body {
    background-color: #141416;
}
.bkg{
    background: #141416 !important;
}
.user {
    background-color: #141416;
    min-height: 100vh;
    &.user-pc{
        display: block;
        padding-top: 80px;
        position: relative;
        padding-bottom: 100px;
    }
    &.user-mobile {
        display: none;
    }
    .user-bkg {
        width: 100%;
        height: 160px;
    }
    
    .info-title {
        font-size: 30px;
    }
    .nav-box {
        width: 280px;
        background-color: #313134;
        padding: 30px 0 30px 0;
        position: absolute;
        left: -330px;
        top: -70px;
        .avatar{
            width: 104px;
            height: 104px;
            border-radius: 50%;
        }
        .nav-item {
            border-left: 3px solid #313134;
            cursor: pointer;
            &:hover {
                color:#E07D26
            }
            &.active {
                border-left: 3px solid #E07D26;
                color:#E07D26
            }
        }
        .nav-icon{
            width: 16px;
            height: 16px;
            margin: 0 21px 0 28px;
        }
    }
    .user-content {
        max-width: 600px;
        margin: 0 auto;
        position: relative;
        &.rewards {
            // max-width: 812px;
            // margin-left: 400px;
        }
        .chainName{
            img{
                width:32px;
                height: 32px;
            }
        }
        .avatar-setting{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-left: auto;
            position: relative;
            .avatar {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 1px solid #313134;
            }
            .setting {
              position: absolute;
              top: 30px;
              left:30px;
            }
        }
        .my-input{
            background-color: #313134 !important;
            color: #fff !important;
            height: 60px;
            line-height: 60px;
        }
        .address-input{
            position: relative;
            background-color: #313134;
            padding: 0 20px 0 20px;
            height: 60px;
            .bind{
                position: absolute;
                right: 0px;
                height: 60px;
                line-height: 60px;
                bottom: 0;
                background-color: #313134;
                padding: 0 20px 0 20px;
            }
            .tw-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 20px;
                top: 30px;
            }
        }
        .social-input {
            position: relative;
            input {
                background-color: transparent !important;
                border:1px solid  #313134;
                &:focus {
                    border-color: #E07D26;
                }
            }
            // margin-top:10px;
            .error-tip {
                position: absolute;
                left: 105%;
                width: 90%;
                bottom: 0px;
            }
            .tw-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 20px;
                top: 20px;
            }
            .send {
                position: absolute;
                right: 10px;
                color: #E07D26;
                // background-color: rgba(224,125,38,0.1);
                // border: 1px solid #E07D26;
                display: inline-block;
                line-height: 32px;
                padding: 0 15px;
            }
        }
        .confirm-btn{
            width:236px;
        }
        .input-btn {
            position: absolute;
            left: 0;
            top:0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 99;
        }
    }
    .account{
        // padding: 20px;
    }
    .email {
        margin-left: 120px;
        margin-top:0px;
    }
    .setting-title {
        font-size: 24px;
    }
    .setting {
        flex-direction: row;
    }
    .content-font {
        font-size: 18px;
    }
    .money-box {
        margin-top: 30px;
    }
    .money {
        font-size: 32px;
    }
    .asset-box {
        border: 1px solid #242429;
        padding: 12px;
        .logo{
            width: 32px;
            height:32px;
        }
    }
    .nft-area {
        .cover{
            width: 180px;
            height: 180px;
        }
    }
    .search-area {
        .search-icon{
            width: 32px;
            height: 32px;
            background: #313134;
            margin-left: auto;

        }
        .nft-type {
            padding: 6px 12px;
            border: 1px solid #313134;
            margin-right: 12px;
            color: rgba(255,255,255, .6);
            &.active {
                border-color: #fff;
                color: #fff;
            }
        }
    }
    .setting-area{
        margin-top: 30px;
    }
    .setting-btn {
        justify-content: flex-end;
        margin-top: 30px;
    }
    .change-margin {
        margin-top: 40px;
    }
    
    .game-title {
        padding: 60px 0 30px 0
    }
    .profile {
        width: 100%;
        // border: 1px solid #313134;
        padding: 30px 0px 30px 0;
        .eye {
            opacity: 0.6;
        }
    }

    .back {
        img{
            width: 20px;
        }
    }
    .game {
        width: 100%;
        
        .game-btn {
            border: 1px solid #57575A;
            font-size: 16px;
            line-height: 36px;
            display: inline-block;
            padding: 0 15px;
            margin-left: 10px;

        }
        .change-btn {
            position: absolute;
            right: 0;
            div{
                padding: 0 16px;
                cursor: pointer;
            }
            .line{
                width: 1px;
                height:20px;
                background-color: #3A3A3C;
                display: block;
            }
        }
        .game-tip {
            padding: 10px 25px 10px 10px;
            background-color: rgba(224, 125, 38, 0.1);
            border: 1px solid rgba(224, 125, 38, 0.2);
            margin-bottom: 20px;
            &.game-warn {
                padding: 17px 25px 17px 35px;
                margin-bottom: 0px;
            }
            &.agree {
                background-color: rgba(73, 170, 25, 0.3);
                border-color: rgba(73, 170, 25, 1);
                .my-checkbox-rect .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: rgba(73, 170, 25, 1);
                    border-color: rgba(73, 170, 25, 1);
                }
            }
            .close-icon {
                margin-left: auto;
                width: 11px;
                height: 11px;
            }
            .info-icon {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
        .change-tip {
            // position: fixed;
            bottom: 0;
            left:0;
            right: 0;
            width: 100%;

        }
        
    }
    .asset-area {
        img {
            width: 150px;
            margin-right: 20px;
            margin-top: 20px;
        }
    }

}
.modify-avatar {
    .avatar-item {
        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
    }
    .nft-wrap {
        max-height: 200px;
        overflow-y: auto;
    }
    .desc {
        word-break: break-all;
    }
    .nft-img {
        width: 50px;
        position: relative;
        .loading {
            width: 48px;
            height: 48px;
            color: #999;
            background-color: #eee;
            z-index: -1;
        }
        .nft-img-image, .ant-image {
            position: absolute;
            width: 48px;
            height: 48px;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            overflow: hidden;
        }
        &.active {
            border: 1px solid #E07D26
        }
    }
}
@media screen and (max-width: 768px) {
    .user {
        padding-bottom: 60px;
        &.user-pc {
            display: none;
        }
        &.user-mobile {
            display: block;
            padding-top: 60px;
            
            .money-box {
                margin-top: 0px;
                
                padding: 20px 0 0 0
            }
            .money {
                font-size: 20px;
            }

            .info-title {
                font-size: 16px;
            }
            .game-title {
                width: 100%;
                padding: 20px 0
            }
            .profile {
                width: 100%;
                // border: 1px solid #313134;
                padding: 0px 0px 0px 0;
                .eye {
                    opacity: 0.6;
                }
            }
            .user-bkg {
                width: 100%;
                height: 80px;
            }
            .nav-box {
                width: 100%;
                background-color: transparent;
                position: relative;
                left: 0;
                padding: 0;
                top:-30px;
                .nav-item{
                    display: none;
                }
                .avatar {
                    border: 2px solid #141416
                }
            }
            .user-content {
                max-width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
            .email {
                margin-left: 0;
                margin-top:20px;
            }
            .account{
                padding-top: 20px;
            }
            .setting-title {
                font-size: 18px;
            }
            .setting {
                flex-direction: column;
            }
            .content-font {
                font-size: 14px;
            }
            .setting-area{
                margin-top: 15px;
            }
            .address-input{
                padding: 0 10px 0 10px;
            }
            .setting-btn {
                justify-content: center;
                margin-top: 30px;
            }
            .change-margin {
                margin-top: 30px;
            }
            .confirm-btn {
                width: 100%;
            }
            
        .social-input {
            position: relative;
            input {
                background-color: transparent !important;
                border:1px solid  #313134;
                color: #fff !important;
                &:focus {
                    border-color: #E07D26;
                }
            }
            // margin-top:10px;
            .error-tip {
                position: absolute;
                left: 105%;
                width: 90%;
                bottom: 0px;
            }
            .tw-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 20px;
                top: 20px;
            }
            .send {
                position: absolute;
                right: 10px;
                color: #E07D26;
                // background-color: rgba(224,125,38,0.1);
                // border: 1px solid #E07D26;
                display: inline-block;
                line-height: 32px;
                padding: 0 15px;
            }
        }
        }
    }
    
}