.cf {
    color: #fff
}
.c56{
    color: #565656
}
.c06 {
    color:rgba(255,255,255,0.6)
}
.ce {
    color:#E07D26
}
.color{
    background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
}
.blue-color {
    background: linear-gradient(90deg, #3E3FBA 0%, #2984EB 100%);
}
.bgf {
    background-color: #fff;
}
.bg31{
    background-color: #313134;
}
.bg59{
    background-color: #595959;
}
.bge{
    background-color: #E07D26;
}
.bde {
    border:1px solid #E07D26
}
.c14 {
    color: #141416
}
.c8c{
    color:#8C8C8C
}
.ffm {
    font-family: 'Kanit Medium, Kanit' !important;
}
.bg3a {
    background: #3A3A3A;
}
.cd2{
    color: rgba(20, 20, 22, 0.2)
}
.bg2{
    background-color: #222224;
}
.bg10{
    background-color: #101010;
}
.c006{
    color: rgba(0,0,0,0.6)
}