body{
    background-color: #141416;
}
.nft-cover{
    max-width: 582px;
    width: 35%;
    .cover {
        width: 100%;
        overflow: hidden;
        // padding-bottom: 100%;
        position: relative;
        // height: 582px;
        model-viewer{
            width: 100%;
            height: 100%;
            background-color: #313134;
            position: absolute;
            top: 0;
        }
        img, video{
            width: 100%;
            transition: all 0.5s;
        }
        &:hover {
            img{
                transform: rotate(90deg);
                transform: scale(1.2);
            }
        }
    }
    
}
.token{
    width:24px;
}
.buy-btn{
    width: 280px;
    height: 64px;
    background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
    line-height: 64px;
}
.list-btn {
    width: 280px;
    height: 64px;
    border: 1px solid;
    background-color: transparent;
    border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1;
   
    &:hover{
        background: transparent !important;
        border: 1px solid  !important;
        background-color: transparent  !important;
        border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1  !important;
    }
    
    &:active{
        background: transparent !important;
        border: 1px solid  !important;
        background-color: transparent  !important;
        border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1  !important;
    }
    .list-text {
       font-size: 20px;
       background-image: linear-gradient(137deg, #3B3AD0 0%, #FC7500 100%);
       background-clip: text;
       -webkit-text-fill-color: transparent;
       color: transparent
   }
}
.num-input {
    width: 100%;
    border: none;
}
.prop-wrap {
    width: 690px;
}
.prop {
    border: 1px solid #313134;
    width: 210px;
    height: 88px;
    margin-top: 10px;
    &.offset {
        border:none
    }
}
.basic-title{
    width: 171px;
}
.nft-open-item {
    min-width: 30%;
    flex:1
}
.img-box{
    position: relative;
    .cover{

        width: 180px;
        height: 180px;
    }
    .color-line{
        width:43px;
        height: 1px;
        box-sizing: border-box;
        border: 1px dashed;
        position: relative;
        border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1;
       .white-line{
          position: absolute;
          width:43px;
          height: 1px;
          left: 0;
          top:-1px;
          box-sizing: border-box;
          border: 1px dashed #fff;
       }
    }
    .right{
        background-color: #2F2F38;
        width: 40px;
        height:40px;
        position: absolute;
        right: -12px;
        bottom: -12px;
        border-radius: 50%;
        border:1px solid #fff;
    }
}
.hide{
    height: 210px;
    overflow: hidden;
}
.show-icon {
    transform: rotateX(180deg);
}
.currency-box {
    .currency-item {
        border: 1px solid rgba(221,221,221, 0.3);
        width: 112px;
        height: 32px;
        line-height: 30px;
        margin-right: 12px;
        margin-top: 10px;
        &:nth-child(3n) {
            margin-right: 0;
        }
        &.active {
            background-color: #E07D26;
            color: #fff
        }
    }
}

.fee-box {
    background-color: rgba(110, 110, 110, 0.1);
    padding: 14px 16px;
    .circle{
        background: #B8B8B8;
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
    }
}
.maker-address {
    &:hover {
        color: #E07D26
    }
}