.farm-overview {
  padding: 120px 40px 20px;
  color: #fff;
  min-height: 100vh;
  background: url(../../assets/images/farm/farm-bg.png) no-repeat center / cover;

  .farm-overview-content {
    margin: -20px;
  }

  .card {
    background: #1D1D22;
    padding: 30px;
    margin: 20px;
    height: calc(100% - 40px);

    .card-title {
      font-size: 18px;
      line-height: 32px;
    }
    .card-subtitle {
      font-size: 14px;
      opacity: .6;
    }
  }

  .token-balance {
    display: flex;
    align-items: baseline;
    .balance {
      font-size: 40px;
    }
    .unit {
      font-size: 20px;
      margin-left: .5em;
    }
  }

  .fiat-balance {
    font-size: 14px;
    opacity: .6;
  }

  .card-kline {
    flex: 2;

    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
    }

    .icon-tangle {
      margin-left: 5px;
    }

    .card-title {
      cursor: pointer;
    }

    .pair-info {
      display: flex;
      align-items: baseline;
      margin-left: 30px;
      .price {
        font-size: 18px;
      }
      .pair {
        font-size: 18px;
        margin-left: .5em;
      }
      .change {
        font-size: 18px;
        margin-left: .5em;
        color: #E07D26;
      }
    }

    .switch-chain {
      margin-left: auto;
      display: flex;
      align-items: center;
      height: 32px;
      background: #303034;
      padding: 8px;
      font-size: 14px;
      cursor: pointer;

      .chain-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      .icon-tangle {
        margin-left: 16px;
      }


    }

    .token-logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .kline-header {
      display: flex;
      align-items: center;

      .kline-selector {
        margin-right: auto;
        display: flex;
        align-items: center;
      }

      .range-group {
        // margin-left: auto;
        display: flex;

        .range-item {
          font-size: 14px;
          padding: 5px 10px;
          cursor: pointer;

          &.active {
            background: rgba($color: #D8D8D8, $alpha: .1)
          }
        }

      }

      .ktype-group {
        display: flex;
        align-items: center;
        margin-right: 15px;
        border: 1px solid rgba($color: #979797, $alpha: .1);
        padding: 4px;

        .ktype {
          width: 34px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .active {
          background-color: rgba($color: #D8D8D8, $alpha: .1);
        }
      }
    }

    .timestamp {
      font-size: 14px;
      opacity: .6;
    }
  }

  .card-balance {
    flex: 1;

    background: url(../../assets/images/farm/card-image2.png) #1D1D22 no-repeat calc(100% + 28px) center / 111px 92px;
  }

  .card-staked {
    flex: 1;
    background: url(../../assets/images/farm/card-image1.png) #1D1D22 no-repeat right center / 83px 111px;

    .staked-value {
      font-size: 32px;
      margin-top: 30px;
    }
  }

  .card-rewards {
    flex: 1;

    .rewards-title {
      font-size: 18px;
      margin-top: 35px;
    }

    .btn-rewards {
      width: 220px;
      height: 64px;
      background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rewards-link {
      display: none;
    }
    
  }

  .card-kepe-rewards {
    flex: 1;

    .card-progress {
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .progress-title {
        font-size: 20px;
        line-height: 24px;
        color: rgba($color: #fff, $alpha: .6);
        text-align: center;
        margin-bottom: 20px;
        width: 100px;
      }
      .progress-value {
        font-size: 36px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .card-burned {
    flex: 1;
    background: url(../../assets/images/farm/card-image3.png) #1D1D22 no-repeat calc(100% + 14px) calc(100% + 18px) / 165px 111px;

    .total-burned-image {
      width: 306px;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 18px;
    }

    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 26px;

      .open-link {
        background: #303034;
        margin-left: auto;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 16px;

        .icon-link {
          margin-right: 6px;
        }

        .icon-open-link {
          margin-left: 10px;
        }
      }
    }

    .info-item {
      margin-bottom: 20px;

      .info-item-label {
        font-size: 16px;
        line-height: 20px;
        color: rgba($color: #fff, $alpha: .6);
      }

      .info-item-value {
        font-size: 28px;
        line-height: 24px;
        margin-top: 8px;
      }
    }
  }

  .is-hidden-mobile {
    display: block !important;
  }
  .is-hidden-desktop {
    display: none !important;
  }

  @media (max-width: 768px) {
    padding: 75px 15px 25px;
    color: #fff;

    .farm-overview-content {
      margin: 0;

      & > .flex {
        flex-direction: column;
      }
    }

    .is-hidden-mobile {
      display: none !important;
    }
    .is-hidden-desktop {
      display: block !important;
    }

    .card {
      padding: 15px;
      margin: 0;
      margin-bottom: 15px;
  
      .card-title {
        font-size: 16px;
        line-height: 20px;
      }
      .card-subtitle {
        font-size: 12px;
      }
    }

    .card-kline {
      flex: 2;
  
      .card-header {
        margin-bottom: 6px;

        .card-title {
          font-size: 14px;
        }
      }
  
      .icon-tangle {
        margin-left: 7px;
      }
  
      .pair-info {
        display: flex;
        align-items: baseline;
        margin-left: 0;
        .price {
          font-size: 28px;
        }
        .pair {
          font-size: 14px;
        }
        .change {
          font-size: 14px;
        }
      }
  
      .switch-chain {
        height: 28px;
        padding: 7px 5px;
        font-size: 12px;
  
        .chain-icon {
          margin-right: 5px;
          width: 14px;
          height: 14px;
        }
  
        .icon-tangle {
          margin-left: 27px;
        }
      }
  
      .token-logo {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
  
      .kline-header {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 6px;

        .kline-selector {
          margin-left: 0;
          width: 100%;
          margin-top: 18px;
        }
  
        .range-group {
          display: flex;
  
          .range-item {
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
  
            &.active {
              background: rgba($color: #D8D8D8, $alpha: .1)
            }
          }
  
        }
  
        .ktype-group {
          margin-right: auto;
          padding: 4px;
  
          .ktype {
            width: 34px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
  
          .active {
            background-color: rgba($color: #D8D8D8, $alpha: .1);
          }
        }
      }
  
      .timestamp {
        font-size: 12px;
      }
    }

    .card-balance {
      .token-balance {
        margin-top: 30px;
      }
    }

    .token-balance {
      .balance {
        font-size: 32px;
      }
      .unit {
        font-size: 18px;
      }
    }

    .card-staked {
  
      .staked-value {
        font-size: 26px;
        margin-top: 38px;
      }
    }

    .card-rewards {
      .btn-rewards {
        display: none;
      }
      .token-balance {
        margin-top: 20px;
      }
      .rewards-title {
        font-size: 16px;
        margin-top: 20px;
      }
      .card-header {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        .rewards-link {
          display: block;
          margin-left: auto;
          font-size: 14px;
          color: #E07D26;
        }
      }
    }

    .card-kepe-rewards {
      .card-subtitle {
        font-size: 14px;
      }
  
      .card-progress {
        margin: 22px auto 7px;
  
        .progress-title {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100px;
        }
        .progress-value {
          font-size: 30px;
        }
      }
    }

    .card-burned {
      .card-header {
        margin-bottom: 18px;
  
        .open-link {
          height: 28px;
          font-size: 14px;
        }
      }
  
      .info-item {
        margin-bottom: 20px;
  
        .info-item-label {
          font-size: 14px;
          line-height: 20px;
        }
  
        .info-item-value {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
}

.farm-overview-token-dropdown-menu {
  .token-option {
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    .token-logo {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }
  @media (max-width: 768px) {
    .ant-dropdown-menu-item {
      padding: 15px 20px;
    }
    .token-option {
      font-size: 14px;
      line-height: 32px;
      .token-logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}

.farm-overview-chain-dropdown-menu {
  .ant-dropdown-menu-item-disabled {
    opacity: .4;
    pointer-events: none;
  }
  .chain-option {
    font-size: 18px;
    line-height: 32px;
    color: #fff;

    .chain-icon {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }

    .chain-name {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    .ant-dropdown-menu-item {
      padding: 15px 20px;
    }
    .chain-option {
      font-size: 14px;
      line-height: 32px;
      color: #fff;
  
      .chain-icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
  
      .chain-name {
        font-size: 14px;
      }
    }
  }

}
