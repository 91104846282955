.flex {
    display: flex;
}
.flex-center {
    align-items: center;
}

.flex-around {
    justify-content: space-around;
}
.flex-between {
    justify-content: space-between;
}
.flex-middle{
    justify-content: center;
}
.flex-column {
    flex-direction: column;
}
.flex-1{
    flex: 1
}
.flex-2{
    flex: 2
}
.flex-start{
    align-items: flex-start;
}
.flex-end{
    align-items: flex-end;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-ab {
    align-self: baseline;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-last {
    justify-content: flex-end;
}
.flex-first {
    justify-content: flex-start;
}
.flex-right{
    margin-left: auto;
    margin-right: 0;
}