
// $background-color:#141414;
body{
//  background:$background-color; 
//   width:100%;
//   height:100%;
}
.loading-text{
   text-transform:uppercase;
   font-family: 'Cabin Condensed', sans-serif;
  font-weight:bold;
  font-size:30pt;
  text-align:center;
  height:60px;
  line-height:60px;
//   vertical-align:bottom;
//   position:absolute;
//   left:0;
//   right:0;
//   top:100px;
//   bottom:0;
  display:block;
}

.loading-2{
  top:300px;
  width:473px;
  height:97px;
  font-size:0;
  background:rgba(255,255,255,0.06);
  margin:0 auto;
}
.ff{
  position:absolute;
  font-size:12pt;
  top:-20px;
  color:white;
  line-height:12pt;
  
}
.loading-2-text{
  background-image:url('./bg1.jpg');
  width:473px;
  height:97px;
  display:inline-block;
}
@keyframes wave-animation{
  0%{background-position:0 bottom}
  100%{background-position:200px bottom};
}
@keyframes loading-animation{
  0%{background-size:200px 0px}
  100%{background-size:200px 200px};
}
.wave{
   background-image:url('./bg2.jpg');
   -webkit-background-clip:text;
  color:rgba(0,0,0,0);
  text-shadow:0px 0px rgba(255,255,255,0.06);
  animation:wave-animation 1s infinite linear, loading-animation 10s infinite linear alternate;
  background-size:200px 100px;
  background-repeat:repeat-x;
  opacity:1;
}
.wave-2{
  -webkit-background-clip:initial;
  display:inline-block;
}