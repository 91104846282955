.nft-assets{
    top: -40px;
    position: relative;
.nft{
    width: 225px;
    padding: 15px;
    .cover{
        width: 210px;
        height: 210px;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
            transition: all 0.5s;
        }
        &:hover {
            .cover-img{
                transform: rotate(90deg);
                transform: scale(1.2);
            }
        }
        .pad {
            height: 24px;
            background: rgba(20, 20, 22, 0.4);
            position: absolute;
            padding: 0 9px;
            line-height: 24px;
            &.time{
                left:10px;
                top:10px;
            }
            
            &.share-desc{
                right:10px;
                bottom:10px;
            }
            
            &.price-desc{
                right:10px;
                top:10px;
            }
        }
    }
}
}