.market {
  // background-color: #141416;
  .market-content {
    padding: 60px 40px;
  }
  .market-my-content {
    padding-top: 120px;
  }
}
@media screen and (max-width: 768px) {
  .market {
    // background-color: #141416;
    .market-content {
      padding: 20px 15px;
    }
    .market-my-content {
      padding-top: 40px;
    }
  }
}