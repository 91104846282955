.select-mint {
  // width: 200px;
  height: 49px;
  &.normal {
  }
  &:hover {
  }
  &.active {
    .ant-select {
      border: 1px solid #E07D26;
      .ant-select-selection-item {
        color: #E07D26;
      }
    }
  }
  .ant-select {
    width: 100% !important;
    border: 1px solid #5D5D5D;
    border-radius: 4px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 49px;
    color: #fff;
    border: 1px solid #5D5D5D;
    padding: 0 20px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 49px;
    line-height: 49px;
  }
  .ant-select {
    color: #fff;
  }
  .ant-select-arrow {
    display: flex;
    align-items: center;
    right: 20px;
  }
  .ant-select-selection-item {
    height: 49px;
    line-height: 49px;
    font-size: 16px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    height: 49px;
    line-height: 49px;
    font-size: 16px !important;
  }
  .ant-select-open {
    border: 1px solid #E07D26;

    .ant-select-selection-item {
      color: #E07D26;
    }

    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }
}

.select-dropdown.ant-select-dropdown {
  border-radius: 0;
  background-color: #5D5D5D;
  color: #fff;
  .ant-select-item {
    color: #fff;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #5D5D5D;
    color: #fff;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #4A4A52;
  }
  .ant-select-item {
    height: 45px;
    padding: 0 20px;
    font-size: 16px;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .search {
    width: auto;
  }
}
