.download-container {
    height: 100%;
    background: url('../assets/images/download/bg.jpg') right top / cover no-repeat;
}

html.has-scroll-smooth {
    overflow: auto;
}
html, body{
    height: max-content;
    &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 1px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }
          &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background   : rgba(0, 0, 0, 0.2);
        }
          &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background   : rgba(0, 0, 0, 0.2);
        }
}
.inner-container {
    width: 1200px;
    margin: 0 auto;
    .download-area {
        height: 679px;
        position: relative;
        background: url('../assets/images/download/group.png') right top / cover no-repeat;
        background-size: 913px 679px;
        transform: translateX(100px);
        .area-bg {
            position: absolute;
            top: 0;
            right: 0;
        }
        .big-title {
            font-family: Serif !important;
            font-weight: bold;
        }
        .download-btn {
            width: 246px;
            height: 80px;
            background: url('../assets/images/download/button.png') left top / cover no-repeat;
        }
    }
    .desc{
        width: 618px;
        height: 407px;
        background: url('../assets/images/download/desc-bg.png') left top / cover no-repeat;;
        .desc-text{
            width:514px;
        }
    }
    .table-wrap {
        margin: 0 auto;
        width: 989px;
        
    .table {
        td {
            padding: 20px;
        }
    }

    }
}