.road-map {
    .btns {
        .btn-item {
            opacity: 0.3;
            display: inline-block;
            padding: 7px 20px;
            border: 1px solid #fff;
            border-radius: 36px;
            margin-right: 20px;
            &.active {
                opacity: 1;
            }
        }
      
    }
    .road-time {
        width: 107px;
        text-align: left;
    }
    .road-content {
        margin-top: 53px;
    }
    .road-text {
        position: relative;
        border-left: 1px dashed rgba(34, 34, 36, 1);
        padding-left: 53px;
        &.last-one {
            border-left: none;
        }
        .point {
            width: 20px;
            height: 20px;
            border: 1px solid #222224;
            position: absolute;
            top: 5px;
            left: -10px;
            border-radius: 50%;
            background-color: #000;
            z-index: 9;
            .heart {
                width: 7px;
                height: 7px;
                background: #E07D26;
                border-radius: 4px;
            }
        }
        .first-black {
            background-color: #000;
            width: 20px;
            height: 20px;
            position: absolute;
            
            top: -16px;
            z-index: 9;
            left: -10px;
        }
    }
    .time-inner {
        display: none;
    }
    .time-out {
        display: block;
    }
    @media screen and (max-width: 768px) {
        .time-inner {
            display: block;
        }
        .time-out {
            display: none;
        }
        .road-text {
            font-size: 14px !important;
            padding-left: 20px !important;
            padding-top: 5px;
        }
        .road-content {
            margin-top: 20px;
        }
     }
}
