body{
    background-color: #141416;
}
.nft-content {
    flex-direction: row;
    align-items: flex-start;
}
.nft-detail {
    padding: 100px 40px 60px 40px;
    
    .nft-info {
        margin-left: 50px;
        
        .ownedby {
            margin-left: 20px;
        }
        
        .nftname {
            font-size: 32px;
            margin-bottom: 20px;
        }
        .desc {
            margin-top:40px
        }
        .Rare {
            background-color: #3B3AD0;
        }
        
        .Epic {
            background-color: #E07D26;
        }
        
        .Legendary {
            background-color: #794d8d;
        }
        .price-area {
            flex-direction: row;
            .price-item {
                margin-top: 40px;
            }
        }
    }
}
.nft-cover{
    max-width: 582px;
    width: 35%;
    .cover {
        width: 100%;
        overflow: hidden;
        // padding-bottom: 100%;
        position: relative;
        // height: 582px;
        model-viewer{
            width: 100%;
            height: 100%;
            background-color: #313134;
            position: absolute;
            top: 0;
        }
        img{
            width: 100%;
            transition: all 0.5s;
        }
        .loading {
            width: 100%;
            padding-top: 50%;
            padding-bottom: 50%;
            text-align: center;
        }
        &:hover {
            img{
                transform: rotate(90deg);
                transform: scale(1.2);
            }
        }
    }
    
}
.token{
    width:24px;
    border-radius: 50%;
}
.buy-btn{
    width: 360px;
    height: 64px;
    background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
    line-height: 64px;
}
.prop-wrap {
    width: 690px;
}
.prop {
    border: 1px solid #313134;
    width: 210px;
    height: 88px;
    margin-top: 10px;
    &.offset {
        border:none
    }
}
.basic-title{
    width: 171px;
}
.img-box{
    position: relative;
    .cover{

        width: 180px;
        height: 180px;
    }
    .color-line{
        width:43px;
        height: 1px;
        box-sizing: border-box;
        border: 1px dashed;
        position: relative;
        border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1;
       .white-line{
          position: absolute;
          width:43px;
          height: 1px;
          left: 0;
          top:-1px;
          box-sizing: border-box;
          border: 1px dashed #fff;
       }
    }
    .right{
        background-color: #2F2F38;
        width: 40px;
        height:40px;
        position: absolute;
        right: -12px;
        bottom: -12px;
        border-radius: 50%;
        border:1px solid #fff;
    }
}

.currency-box {
    .currency-item {
        border: 1px solid rgba(221,221,221, 0.3);
        width: 112px;
        height: 32px;
        line-height: 30px;
        margin-right: 12px;
        margin-top: 10px;
        &:nth-child(3n) {
            margin-right: 0;
        }
        &.active {
            background-color: #E07D26;
            color: #fff
        }
    }
}

.fee-box {
    background-color: rgba(110, 110, 110, 0.1);
    padding: 14px 16px;
    .circle{
        background: #B8B8B8;
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
    }
}
.maker-address {
    &:hover {
        color: #E07D26
    }
}

.fz-mini {
    font-size: 14px;
}
.fz-med {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    .nft-detail {
        padding: 80px 15px 60px 15px;
    }
    .nft-content {
        flex-direction: column;
        align-items: flex-start;
        .nft-cover{
            max-width: 100%;
            width: 100%;
            .cover {
                width: 100%;
                overflow: hidden;
                // padding-bottom: 100%;
                position: relative;
                // height: 582px;
                model-viewer{
                    width: 100%;
                    height: 100%;
                    background-color: #313134;
                    position: absolute;
                    top: 0;
                }
                img{
                    width: 100%;
                    transition: all 0.5s;
                }
                &:hover {
                    img{
                        transform: rotate(90deg);
                        transform: scale(1.2);
                    }
                }
            }
            
        }
        .nft-info {
            margin-left: 0;
            width: 100%;
            margin-top:20px;
            .nft-name-area {
                flex-direction: column;
            }
            .ownedby {
                margin-left: 0px;
            }
            .nft-name-area {
                font-size: 14px;
            }
            .nftname {
                font-size: 20px;
                margin-bottom: 10px;
            }
            .desc {
                margin-top:20px
            }
            .price-area {
                flex-direction: column;
                align-items: flex-start;
                .price-item {
                    margin-top: 20px;
                }
            }
        }
        .buy-btn {
            width: 100%
        }
    }
    
    .prop-wrap {
        width: 100% !important;
    }
    .prop {
        width: 48% !important;
        height: 76px !important;
    }
    .fz-mini {
        font-size: 12px;
    }
    .fz-med {
        font-size: 14px;
    }
}