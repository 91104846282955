.token-box {
    padding: 4px;
    border: 1px solid rgba(151, 151, 151, 0.4);
    display: inline-block;
    .token-item {
        display: inline-block;
        padding: 4px 20px;
        &.active {
            background-color: rgba(224, 125, 38, 1);
            color: #fff
        }
    }
}
.choose {
    .choose-token {
        width: 100%;
        height: 90px;
        background: rgba(235, 235, 235, 1);
        .number-input {
            background: rgba(235, 235, 235, 1);
            border: none;
            max-width: 200px;
        }
    }
    .select-box {
        width: 130px;
    }
    .percent {
        .percent-item {
            width:40px;
            height:22px;
            background: rgba(235, 235, 235, 1);
            border-radius: 16px;
            line-height: 20px;
            margin-left: 6px;
            border: 1px solid rgba(76, 76, 76, 0.4);
            &.active {
                border-color: rgba(224, 125, 38, 1);
                color: rgba(224, 125, 38, 1)  !important;
                background: rgba(224,125,38,0.24);;
            }
        }
    }

}
.transmit-btn {
    height: 48px;
}
// .claim-wrap {
//     max-height: 400px;
//     overflow: scroll;
// }