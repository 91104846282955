.apply-volunteer {
  padding: 120px 40px 40px;
  
  .apply-volunteer-header {
    display: flex;
    align-items: center;

    .apply-volunteer-crumbs {
      color: #fff;
      font-size: 16px;

      .cur {
        color: #E07D26;
      }
    }

    .btn-return {
      margin-left: auto;
      height: 36px;
      padding: 0 28px;
      font-size: 14px;
      color: #fff;
      background: #2E3689;
    }
  }

  .apply-volunteer-card {
    width: 610px;
    max-width: 100%;
    background: #fff;
    margin: 60px auto 0;
    padding: 15px 30px 30px;

    &.unabled {
      pointer-events: none;
    }

    .card-title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .card-desc {
      font-size: 16px;

      a {
        color: #E07D26;
        text-decoration: underline;
      }
    }

    .label {
      color: #727273;
    }
    .tip {
      color: #727273;
    }

    .btn-submit {
      width: 100%;
      height: 54px;
      background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      color: #fff;
      border-radius: 0;
      font-size: 18px;
    }
  }
  .other-checkbox {
    display: flex;
    input {
      max-width: 100px;
      border: 0;
      outline: none;
      border-bottom: 1px solid #111111;
      &:focus{
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #111111;
      }
    }
  }

  .other-wrapper {
    display: flex;

    .other-label {
      flex-shrink: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 65px 15px 15px;

    .apply-volunteer-header {
  
      .apply-volunteer-crumbs {
        font-size: 14px;
      }
  
      .btn-return {
        height: 30px;
        padding: 0 24px;
        font-size: 12px;
      }
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-form label {
      font-size: 12px;
    }
  
    .apply-volunteer-card {
      margin: 15px auto 0;
      padding: 15px;
  
      .card-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .card-desc {
        font-size: 14px;
      }
  
      .btn-submit {
        height: 40px;
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }
    .other-checkbox {
      input {
      }
    }
  }
}