@keyframes border-dance {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

.Home {
  background-color: #141416;
  color: #fff;
  position: fixed;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 767.5px) {
    position: relative;
  }

  .bracket {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .bracket-wrap {
    position: relative;
  }
  .bracket-wrap[data-scroll] .bracket-bl,
  .bracket-wrap[data-scroll] .bracket-br,
  .bracket-wrap[data-scroll] .bracket-tl,
  .bracket-wrap[data-scroll] .bracket-tr {
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s;
  }
  .bracket-wrap[data-scroll] .bracket-tl {
    transform: translate(500%, 500%);
  }
  .bracket-wrap[data-scroll] .bracket-tr {
    transform: translate(-500%, 500%);
  }
  .bracket-wrap[data-scroll] .bracket-br {
    transform: translate(-500%, -500%);
  }
  .bracket-wrap[data-scroll] .bracket-bl {
    transform: translate(500%, -500%);
  }
  .bracket-wrap[data-scroll] img {
    // opacity: 0;
  }
  .bracket-wrap[data-scroll].is-inview .bracket-bl,
  .bracket-wrap[data-scroll].is-inview .bracket-br,
  .bracket-wrap[data-scroll].is-inview .bracket-tl,
  .bracket-wrap[data-scroll].is-inview .bracket-tr {
    transform: translate(0);
    opacity: 1;
  }
  .bracket-wrap[data-scroll].is-inview img {
    -webkit-animation: in-picture 1.2s linear forwards;
    animation: in-picture 1.2s linear forwards;
  }
  .bracket-wrap[data-scroll].is-inview .bracket-copy {
    -webkit-animation: in-picture-copy 1s forwards;
    animation: in-picture-copy 1s forwards;
  }
  .bracket-bl,
  .bracket-br,
  .bracket-tl,
  .bracket-tr {
    position: absolute;
    z-index: 1;
  }
  .bracket-tl {
    top: 0;
    left: 0;
  }
  .bracket-tr {
    top: 0;
    right: 0;
  }
  .bracket-br {
    bottom: 0;
    right: 0;
  }
  .bracket-bl {
    bottom: 0;
    left: 0;
  }
  .bracket-copy {
    position: absolute;
    z-index: 1;
    color: hsla(0, 0%, 100%, 0.5);
    opacity: 0;
  }
  .bracket-copy.-white {
    color: #fff;
  }

  @media screen and (max-width: 767.5px) {
    .bracket-bl,
    .bracket-br,
    .bracket-tl,
    .bracket-tr {
      width: 1.33333vw;
      height: 1.33333vw;
    }
    .bracket-tl {
      border-left: 1px solid hsla(0, 0%, 100%, 0.28);
    }
    .bracket-tl,
    .bracket-tr {
      border-top: 1px solid hsla(0, 0%, 100%, 0.28);
    }
    .bracket-br,
    .bracket-tr {
      border-right: 1px solid hsla(0, 0%, 100%, 0.28);
    }
    .bracket-bl,
    .bracket-br {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.28);
    }
    .bracket-bl {
      border-left: 1px solid hsla(0, 0%, 100%, 0.28);
    }
    .bracket-copy {
      bottom: 1.06667vw;
      right: 1.06667vw;
      font-size: 2vw;
    }
    .bracket-copy.-left {
      left: 1.06667vw;
      right: auto;
    }
  }

  @media screen and (min-width: 768px) {
    .bracket-bl,
    .bracket-br,
    .bracket-tl,
    .bracket-tr {
      width: 6px;
      height: 6px;
    }
    .bracket-tl {
      border-left: 1px solid hsla(0, 0%, 100%, 0.7);
    }
    .bracket-tl,
    .bracket-tr {
      border-top: 1px solid hsla(0, 0%, 100%, 0.7);
    }
    .bracket-br,
    .bracket-tr {
      border-right: 1px solid hsla(0, 0%, 100%, 0.7);
    }
    .bracket-bl,
    .bracket-br {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.7);
    }
    .bracket-bl {
      border-left: 1px solid hsla(0, 0%, 100%, 0.7);
    }
    .bracket-copy {
      bottom: 8px;
      right: 8px;
      font-size: 10px;
    }
    .bracket-copy.-left {
      left: 8px;
      right: auto;
    }
  }


  .play {
    width: 15.625vw;
    height: 15.625vw;
    position: absolute;
    left: 13.07291666666667vw;
    // top: 31.71875vw;
    top: 19.27083333333333vw;
    // top: 80%;
    transition-property: color,transform;
    transition-duration: 1s;
    transition-timing-function: ease,ease,cubic-bezier(.19,1,.22,1);
    z-index: 100;
    // opacity: 0;
    // display: none;
    // pointer-events: none;
    cursor: pointer;

    &:hover {
      opacity: .4;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

    &.show {
      opacity: 1;
      cursor: pointer;
    }

    .play-circle {
      width: 15.625vw;
      height: 15.625vw;
      position: absolute;
      left: 0;
      top: 0;
    }

    .play-text {
      width: 15.625vw;
      height: 15.625vw;
      position: absolute;
      left: 0;
      top: 0;
      animation: rotate 4s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .btn-play {
      width: 3.17708333333333vw;
      height: 3.75vw;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .intro {
    position: relative;

    @media screen and (min-width: 768px) {
      margin-top: -75px;
      padding-top: 175px;
      height: 56.25vw;
      margin-bottom: 5.625vw;
    }

    @media screen and (min-width: 1024px) {
      padding-top: 16.35416666666667vw;
    }

    @media screen and (min-width: 1920px) {
      .intro {
        padding-top: 314px;
      }
    }

    .intro-front {
    }

    .intro-title-main {
      font-size: 100px;
      font-weight: bold;
      -webkit-text-stroke: 1px #FFFFFF;
      // width: 740px;
      margin-bottom: 45px;
      text-transform: uppercase;
    }
    .intro-title-sub {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 1px;
      width: 490px;
      // margin-bottom: 85px;
      margin-bottom: 50px;
    }
    .btn-group {
      display: flex;
      .btn-for-win, .btn-for-mac {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 263px;
        height: 72px;
        border: 1px solid #FFFFFF;
        margin-right: 50px;
        cursor: pointer;
        .icon-mac, .icon-win {
          width: 24px;
          height: 24px;
          margin-right: 14px;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        font-size: 24px;
        font-weight: 500;

        &:hover {
          background-color: #fff;
          color: #000;

          // .comingsoon {
          //   color: rgba($color: #000, $alpha: .4);
          // }
        }
      }
      .btn-for-win {
      }
    
      .btn-for-mac {
        img {
          width: 21px;
        }
      }

      .btn-airdrop, .btn-nft-whitelist, .btn-nft-mint {
        // width: 220px;
        padding: 0 10px;
        height: 64px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
        cursor: pointer;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      .btn-airdrop {
        font-size: 12px !important;
        border: 1px solid #FFFFFF;
        text-align: center;
      }
      .btn-nft-mint {
        background: linear-gradient(122deg, #1715FF 0%, #FC7500 100%);
        border: 1px solid #FFFFFF;
      }

      .btn-nft-whitelist {
        background: linear-gradient(327deg, rgba(59, 58, 208, 0.3) 0%, #FC7500 100%);
        border: 1px solid #FFFFFF;
      }

      .comingsoon {
        font-size: 14px;
        // color: rgba($color: #fff, $alpha: .4);
        color: #fff;
        line-height: 1;
        position: absolute;
        padding: 5px 10px 5px 5px;
        background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
        left: 0;
        top: 0;
        transform: translateY(-100%);
        border-top-right-radius: 100px;
      }
    }

    .intro-back {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .intro-bg__picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        img {
          width: 100%;
        }
      }

      .intro-top {
        height: 160px;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.4;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .intro-bottom {
        height: 194px;
        background: linear-gradient(180deg, rgba(20, 20, 22, 0) 0%, #141416 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    @media screen and (max-width: 767.5px) {
      margin-bottom: 28px;
      height: 492px;

      .intro-front {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 84px 14px 0;
      }
      .intro-back {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
  
        .intro-bg__picture {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          display: flex;
          img {
            width: 100%;
          }

          .loop-video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // width: 100%;
            height: 100%;
            // transform: translateX(-50%);
          }
        }
  
      }
      .intro-title-main {
        div {
          font-size: 34px;
        }
        width: 100%;
        margin-bottom: 14px;
        text-align: center;
      }
      .intro-title-sub {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
      }
      .btn-group {
        display: flex;
        flex-direction: column;
        .btn-for-win, .btn-for-mac {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 247px;
          height: 46px;
          border: 1px solid #FFFFFF;
          margin-right: 0;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          font-size: 18px;
        }

        .btn-for-win {
          margin-bottom: 30px;
        }
      
        .btn-for-mac {
          img {
            width: 17px;
          }
        }

        .btn-airdrop, .btn-nft-whitelist, .btn-nft-mint {
          min-width: 250px;
          height: 46px;
          font-size: 14px;
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .intro-back {
        .intro-bg__picture {
          .loop-video {
            transform: translateX(-33%);
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      .intro-title {
        padding-left: 43.22916666666667vw;
      }

      .intro-title-main {
        // width: 38.54166666666667vw;
        margin-bottom: 2.34375vw;
        line-height: 4.6875vw;
        div {
          font-size: 5.20833333333333vw;
          margin-bottom: 1.14583333333333vw;
        }
      }
      .intro-title-sub {
        font-size: 1.35416666666667vw;
        width: 25.52083333333333vw;
        margin-bottom: 2.6041666667vw;
        line-height: 2.08333333333333vw;
      }
      .btn-group {
        .btn-for-win, .btn-for-mac {
          width: 13.69791666666667vw;
          height: 3.75vw;
          margin-right: 2.60416666666667vw;
          img {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: .72916666666667vw;
          }
          font-size: 1.25vw;
        }

        .btn-airdrop, .btn-nft-whitelist, .btn-nft-mint {
          // width: 11.4583333333vw;
          // padding: 0 10px;
          height: 3.3333333333vw;
          margin-right: 1.0416666667vw;
          // font-size: 1.04vw;
          // display: inline-block;
        }

        .comingsoon {
          font-size: 0.7291666666666667vw;
        }
      }
      .btn-for-win {
      }
    
      .btn-for-mac {
        img {
          width: 1.09375vw;
        }
      }
    }
    
    .intro-scroll {
      overflow: hidden;
      position: absolute;
      display: block;
      z-index: 20;
    }
    .intro-scroll__bar {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      background-color: #fff;
      transform: translateX(-50%);
      transform-origin: top;
      -webkit-animation: scroll-bar 3s linear infinite both;
      animation: scroll-bar 3s linear infinite both;
    }
    .intro-scroll__arrow {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
    }
    .intro-scroll__arrow:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: #fff transparent transparent;
      transform-origin: top center;
      -webkit-animation: scroll-arrow 3s linear infinite;
      animation: scroll-arrow 3s linear infinite;
    }
    .intro-scroll__track {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      background-color: hsla(0, 0%, 100%, 0.5);
    }

    .intro-scroll {
      top: 30.55556vw;
      right: 11.11111vw;
      width: 50px;
      height: 152px;
    }
    .intro-scroll__bar {
      margin-left: -0.5px;
      width: 1px;
    }
    .intro-scroll__arrow {
      width: 8px;
      height: 9px;
    }
    .intro-scroll__arrow:after {
      left: -4.5px;
      border-width: 8px 4.5px 0;
    }
    .intro-scroll__track {
      margin-left: -0.5px;
      width: 1px;
    }

    @media screen and (max-width: 767.5px) {
      .intro-scroll {
        // top: 300px;
        // right: 14px;
        display: none;
      }
      .intro-scroll__bar {
      }
      .intro-scroll__arrow {
      }
      .intro-scroll__arrow:after {
      }
      .intro-scroll__track {
      }
    }

    @media screen and (min-width: 768px) {
      .intro-scroll {
        top: 30.55556vw;
        right: 11.11111vw;
        width: 50px;
        height: 152px;
      }
      .intro-scroll__bar {
        margin-left: -0.5px;
        width: 1px;
      }
      .intro-scroll__arrow {
        width: 8px;
        height: 9px;
      }
      .intro-scroll__arrow:after {
        left: -4.5px;
        border-width: 8px 4.5px 0;
      }
      .intro-scroll__track {
        margin-left: -0.5px;
        width: 1px;
      }
    }
    @media screen and (max-width: 374.5px) {
      .intro-scroll__bar,
      .intro-scroll__track {
        margin-left: -0.5px;
        width: 1px;
      }
    }
    @keyframes scroll-bar {
      0% {
        transform: translateY(-50%) scaleY(0);
      }
      20%,
      80% {
        transform: translateY(0) scaleY(0.5);
      }
      to {
        transform: translateY(100%) scaleY(0);
      }
    }
    @keyframes scroll-arrow {
      0%,
      20% {
        transform: scale(0);
      }
      25%,
      80% {
        transform: scale(1);
      }
      85% {
        transform: scale(0);
      }
      to {
        transform: scale(0);
      }
    }
  }

  .about {
    position: relative;

    @media screen and (max-width: 767.5px) {
      padding: 0 14px;
      margin-bottom: 67px;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 10.83333333333333vw;
    }

    .about-item {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      .about-item-left, .about-item-right {
        flex: 1;

        .about-item__picture {
          width: 37.5vw;
          height: 27.08333333333333vw;
          display: block;
          margin: 0 auto;
          img, video {
            width: 100%;
            height: 100%;
          }
        }

        .about-item__video {
          width: 37.5vw;
          height: 21.08333333333333vw;
          display: block;
          margin: 0 auto;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        
        h3 {
          font-size: 60px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        p {
          font-size: 20px;
          opacity: .6;
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media screen and (max-width: 767.5px) {
        flex-direction: column;
        margin-bottom: 28px;

        &:nth-child(2) {
          flex-direction: column-reverse;
        }

        .about-item-left, .about-item-right {
          .about-item__picture {
            width: 100%;
            height: 66.66666666666667vw;
            margin-bottom: 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .about-item__video {
            width: 100%;
            height: 52.5vw;
            display: block;
            margin: 0 auto;
            video {
              width: 100%;
              height: 100%;
            }
          }
          h3 {
            font-size: 24px;
            margin-bottom: 14px;
          }
          p {
            font-size: 15px;
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 84.375vw;
        margin-bottom: 4.6875vw;

        .about-item-left, .about-item-right {
          // flex: 0 0 36.11111vw;
          // width: 36.11111vw;
          // max-width: 36.11111vw;
        }

        .about-item-right {
          margin-left: 4.16666666666667vw;
        }
      }
    }

    .about-back {
      position: absolute;
      top: 20vw;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 767.5px) {
        display: none;
      }
    }

    .planet__picture {
      width: 100%;
      height: 84.89583333vw;
      position: relative;
      opacity: 0;
      transition: opacity .4s;

      &.is-inview {
        opacity: 1;
      }

      .planet__picture {
        width: 100%;
        height: 100%;
      }

      .planet-top {
        height: 194px;
        background: linear-gradient(180deg, #141416 0%, rgba(20, 20, 22, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .planet-bottom {
        height: 194px;
        background: linear-gradient(180deg, rgba(20, 20, 22, 0) 0%, #141416 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .characters {
    position: relative;
    z-index: 20;
    margin-bottom: 10px;

    .characters-title {
      font-size: 100px;
      font-weight: bold;
      margin-bottom: 50px;
      text-align: center;
    }

    .characters-swiper-container {
      width: 1237px;
      margin: 0 auto;
      position: relative;
      padding: 20px 0;

      .swiper {
        padding: 0 56px;
        width: 700px;

        @media screen and (max-width: 767.5px) {
          width: auto;
        }
      }

      .swiper-wrapper {
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 56px;
        height: 56px;
        margin-top: -28px;
        z-index: 10;
      }

      .swiper-button-next {
        right: 0;
        background: url(../assets/images/home/next.png) no-repeat center / contain;
      }

      .swiper-button-prev {
        left: 0;
        background: url(../assets/images/home/prev.png) no-repeat center / contain;
      }

      .swiper-button-next:after, .swiper-button-prev:after {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 56px;
        width: 1125px;
        height: 1px;
        background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 56px;
        width: 1125px;
        height: 1px;
        background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
      }
    }

    .characters-item {
      width: 137px;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: .4;
      position: relative;

      .characters-item-image1 {
        width: 50px;
        // height: 80px;
      }
      .characters-item-image2 {
        width: 100px;
        // height: 39px;
      }
      .characters-item-image3 {
        width: 110px;
        // height: 80px;
      }
      .characters-item-active {
        width: 137px;
        height: 137px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
      }
    }

    .swiper-slide-active {
      .characters-item {
        opacity: 1;
        .characters-item-active {
          opacity: 1;
        }
      }
    }

    @media screen and (max-width: 767.5px) {
      position: relative;
      z-index: 20;
      margin-bottom: 20px;
      padding: 0 14px;

      .characters-title {
        font-size: 34px;
        margin-bottom: 20px;
      }

      .characters-swiper-container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        padding: 10px 0;

        .swiper {
          padding: 0 32px;
        }

        .swiper-button-next, .swiper-button-prev {
          position: absolute;
          top: 50%;
          width: 32px;
          height: 32px;
          margin-top: -16px;
          z-index: 10;
        }

        .swiper-button-next {
          right: 0;
          background: url(../assets/images/home/next.png) no-repeat center / contain;
        }

        .swiper-button-prev {
          left: 0;
          background: url(../assets/images/home/prev.png) no-repeat center / contain;
        }

        .swiper-button-next:after, .swiper-button-prev:after {
          display: none;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
        }
      }

      .swiper-slide {
        display: flex;
        justify-content: center;
      }

      .characters-item {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .4;

        .characters-item-image {
          // width: 61px;
          // height: 70px;
        }
        .characters-item-image1 {
          width: 40px;
          // height: 80px;
        }
        .characters-item-image2 {
          width: 80px;
          // height: 39px;
        }
        .characters-item-image3 {
          width: 88px;
          // height: 80px;
        }
        .characters-item-active {
          width: 80px;
          height: 80px;
        }
      }
    }

  }

  .warrior {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    .warrior-left, .warrior-right {
      padding: 0 50px;
    }

    .warrior-left {
      // width: 45%;
      flex-shrink: 0;
      img {
        width: 32.86458333333333vw;
        height: 44.84375vw;
        display: block;
        margin: 0 auto;
      }
    }

    .warrior-right {
      width: 55%;

      .warrior-right-title {
        font-weight: bold;
        font-size: 60px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      .warrior-right-content {
        font-size: 20px;
        opacity: .6;
      }
    }

    @media screen and (max-width: 767.5px) {
      flex-direction: column;
      width: 100%;
      padding: 0 14px;
      margin-bottom: 67px;

      .warrior-left, .warrior-right {
        padding: 0;
      }
  
      .warrior-left {
        width: 100%;
        margin-bottom: 14px;
        padding: 0 14px;

        img {
          width: 100%;
          height: auto;
        }
      }
  
      .warrior-right {
        width: 100%;
  
        .warrior-right-title {
          font-size: 24px;
          margin-bottom: 14px;
        }
  
        .warrior-right-content {
          font-size: 15px;
        }
      }
    }
  }

  .gallery {
    position: relative;
    z-index: 20;

    .gallery__list {
      display: flex;
      margin: 0 auto;
      justify-content: center;

      .gallery__list-item {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;

        .gallery__list-picture {
          position: relative;
          padding-top: 56.68518518518519%;
        }

        .gallery__list-picture__image {
          position: absolute;
          top: 0;
          left: 0;
        }

        .gallery__list-info {
          margin-top: 1vw;

          .gallery__list-info__no {
            position: absolute;
            left: 1.19791666666667vw;
            font-size: 40px;
            top: 0;
            transform: translateY(-50%);
          }

          .gallery__list-info__text {
            font-size: 24px;
          }

          .gallery__list-info__date {
            font-size: 18px;
            opacity: .4;
          }

        }
      }
    }

    @media screen and (max-width: 767.5px) {
      .gallery__list {
        flex-direction: column;
        padding: 0 14px;

        .gallery__list-item {
          width: 100%;
          margin-bottom: 30px;

          .gallery__list-picture {
            margin-bottom: 15px;
          }

          .gallery__list-picture__image {
            width: 100%;
            height: 66.66666666666667vw;
          }

          .gallery__list-info {
            margin-top: 1vw;
  
            .gallery__list-info__no {
              display: none;
            }
  
            .gallery__list-info__text {
              font-size: 20px;
            }
  
            .gallery__list-info__date {
              font-size: 14px;
            }
          }
        }
      }
    }
  
    @media screen and (min-width: 768px) {
      padding-top: 11.45833333333333vw;
      padding-bottom: 9.89583333333333vw;

      .gallery__list {
        display: flex;

        .gallery__list-item {
          flex-basis: 18.75vw;
          width: 18.75vw;
          max-width: 18.75vw;

          .gallery__list-picture__image {
            width: 18.75vw;
            // height: 13.59375vw;
          }
        }
        .gallery__list-item:not(:first-child) {
          margin-left: 5.20833333333333vw;
        }
      }
    }
  }

  .features {
    position: relative;
    z-index: 20;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    margin-top: 25vw;
    margin-bottom: 8.80208333333333vw;
    width: 15.05208333333333vw;
    height: 54.21875vw;

    .features-title {
      font-size: 100px;
      font-weight: bold;
      position: absolute;
      // left: -7.43056vw;
      transform: translateX(-50%);
      left: 50%;
      top: -15.69444vw;
    }

    .features-picture {
      
    }

    .features-circle {
      position: absolute;
      border-radius: 50%;
      border-style: solid;
      border-color: #8a8a8a;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ccc;
      }

      .features-circle__line {
        position: absolute;
        z-index: 1;
        background: linear-gradient(90deg,#616364 50%,transparent 0);
        background-repeat: repeat-x;
        animation: border-dance 4s linear infinite;
      }
    }

    @media (min-width: 768px) {
      .features-circle {
        border-width: 0.13889vw;
        width: 5vw;
        height: 5vw;

        &::before {
          width: 0.20833vw;
          height: 0.20833vw;
        }

        .features-circle__line {
          background-size: 12px 1px;
          height: 1px;
        }
      }

      .-circle01 {
        top: 4.1458vw;
        left: 3vw;

        .features-circle__line {
          top: 50%;
          right: 2.5vw;
          width: 14.02778vw;
        }
      }

      .-circle02 {
        top: 16.177vw;
        left: 7.6vw;

        .features-circle__line {
          animation-duration: 4s;
          transform: rotate(315deg);
          transform-origin: left bottom;
          top: 50%;
          left: 50%;
          width: 9.5754vw;
        }
      }

      .-circle03 {
        top: 26vw;
        right: 9.6vw;

        .features-circle__line {
          transform: rotate(135deg);
          transform-origin: left top;
          top: 50%;
          left: 50%;
          width: 9.5754vw;
        }
      }

      .features-circle__line {
        background-size: 12px 1px;
        height: 1px;
      }
    }

    .features-item {
      position: absolute;

      &.-item01 {
        top: 4.083333vw;
        left: -30.864583vw;
        width: 27vw;
      }
      &.-item02 {
        top: 6.375vw;
        right: -30.864583vw;
        width: 27vw;
      }
      &.-item03 {
        top: (663vw/1920*100);
        right: 16.520833vw;
        width: 27vw;
      }
      .features-item-title {
        font-size: 60px;
        margin-bottom: 30px;
        font-weight: bold;
        line-height: 1.2;
      }
      .features-item-desc {
        font-size: 20px;
        opacity: .6;
      }
    }

    @media screen and (max-width: 767.5px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      height: auto;
      padding: 0 14px;

      .features-title {
        font-size: 34px;
        position: static;
        transform: none;
        margin-bottom: 30px;
      }

      .features-picture {
        display: none;
      }
  
      .features-circle {
        display: none;
      }

      .features-item {
        position: static;
        margin-bottom: 40px;
  
        &.-item01 {
          width: 100%;
        }
        &.-item02 {
          width: 100%;
        }
        &.-item03 {
          width: 100%;
        }
        .features-item-title {
          font-size: 24px;
          margin-bottom: 14px;
        }
        .features-item-desc {
          font-size: 15px;
        }
      }
    }
  }

  .roadmap {
    position: relative;

    .roadmap-title {
      font-size: 100px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .roadmap-title-sub {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 100px;
    }

    .roadmap-timeline {
      display: flex;
      align-items: center;

      .roadmap-timeline-date-list {
        font-size: 30px;
        margin-right: 130px;
        border-left: 1px solid #fff;
        flex-shrink: 0;

        .roadmap-timeline-date-item {
          margin-bottom: 50px;
          opacity: .4;
          padding-left: 50px;
          position: relative;
          cursor: pointer;

          &.active {
            opacity: 1;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border: 7px solid transparent;
              border-left: 10px solid #fff;
            }
            &::before {
              content: '';
              position: absolute;
              height: 14px;
              background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
              width: calc(100% - 50px + 16px);
              left: 42px;
              bottom: 0;
              z-index: -1;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

      }

      .roadmap-timeline-content {
        font-size: 20px;

        p {
          display: list-item;
          margin-top: .5em;
          margin-bottom: .5em;
        }
      }
    }

    @media screen and (max-width: 767.5px) {
      padding: 0 14px;
      margin-bottom: 40px;

      .roadmap-title {
        font-size: 34px;
        margin-bottom: 20px;
      }
  
      .roadmap-title-sub {
        display: none;
      }
  
      .roadmap-timeline {
        display: flex;
        align-items: center;
        flex-direction: column;
  
        .roadmap-timeline-date-list {
          display: none;
        }

        .roadmap-timeline-swiper-container {
          width: 100%;
          margin: 0 auto 20px;
          position: relative;
          padding: 10px 0;

          .swiper {
            padding: 0 56px;
          }
    
          .swiper-button-next, .swiper-button-prev {
            position: absolute;
            top: 50%;
            width: 32px;
            height: 32px;
            margin-top: -16px;
            z-index: 10;
          }
    
          .swiper-button-next {
            right: 0;
            background: url(../assets/images/home/next.png) no-repeat center / contain;
          }
    
          .swiper-button-prev {
            left: 0;
            background: url(../assets/images/home/prev.png) no-repeat center / contain;
          }
    
          .swiper-button-next:after, .swiper-button-prev:after {
            display: none;
          }
    
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
          }
    
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, #141416 0%, #FFFFFF 49%, #141416 100%);
          }

          .roadmap-timeline-date-item {
            text-align: center;
            height: 32px;
            line-height: 32px;
          }
        }
  
        .roadmap-timeline-content {
          font-size: 15px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .roadmap-timeline {

        .roadmap-timeline-swiper-container {
          display: none;
        }
      }
    }

  }

  .bg-wrapper {
    position: relative;
    .bg-back {
      position: absolute;
      top: 25vw;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 767.5px) {
        display: none;
      }

    }

    .planet__picture {
      width: 100%;
      height: 100.52083vw;
      position: relative;
      opacity: 0;
      transition: opacity .4s;

      &.is-inview {
        opacity: 1;
      }

      .planet__picture {
        width: 100%;
        height: 100%;
      }

      .planet-top {
        height: 194px;
        background: linear-gradient(180deg, #141416 0%, rgba(20, 20, 22, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .planet-bottom {
        height: 194px;
        background: linear-gradient(180deg, rgba(20, 20, 22, 0) 0%, #141416 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .register-email {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    flex-direction: column;

    .register-email-title {
      font-size: 60px;
      font-weight: bold;
      width: 800px;
      text-align: center;
      margin-bottom: 50px;
      text-transform: uppercase;
    }
    .register-email-input {
      width: 800px;
      height: 80px;
      background: #141416;
      border: 1px solid #FFFFFF;
      display: flex;
      input {
        color: #fff;
        flex: 1;
        font-size: 24px;
        background-color: transparent;
        padding: 0 30px;
        border: 0;
      }
      .btn-register {
        width: 250px;
        height: 100%;
        background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
        border-left: 1px solid #FFFFFF;
        margin-left: auto;
        font-size: 24px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 767.5px) {
      padding: 0 14px;

      .register-email-title {
        font-size: 24px;
        width: 100%;
        margin-bottom: 24px;
      }
      .register-email-input {
        width: 100%;
        height: auto;
        background: #141416;
        display: flex;
        flex-direction: column;
        border: 0;

        input {
          color: #fff;
          flex: none;
          font-size: 18px;
          background-color: transparent;
          padding: 0 30px;
          margin-bottom: 16px;
          border: 1px solid #FFFFFF;
          height: 56px;
          text-align: center;
        }
        .btn-register {
          width: 100%;
          height: 56px;
          border-left: 0;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  
  .ant-input:hover, .ant-input:focus, .ant-input-focused {
    border-color: inherit;
    box-shadow: none;
  }
}


.layer-front {
  position: relative;
  z-index: 20;
}

// @media screen and (min-width: 768px) {
//   html:not(.is-tablet) .gallery__list {
//     // padding-left: 11.11111vw;
//     // padding-right: 11.11111vw;
//   }
// }