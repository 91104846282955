.bar {
    // max-width: 568px;
    // width: 100%;
    // height: 38px;
    padding: 5px 0px 5px 0;
    // border: 1px solid rgba(255,255,255,0.3);
    // background-color: rgba(255,255,255,0.2);
    .color-tip {
        width: 0px;
        height: 0px;
        border: 6px solid #fff;
        border-radius: 0px 12px 0 0 ;
    }
    .name {
        // flex:2;
        min-width: 185px;
    }
    .percent {
        width: 50px;
    }
    .desc{
        flex:1;
        // text-align: right;
        margin-left: 16px;
        // ::after {
        //     content: '';
        //     width: 100px;
        //     height: 40px;
        //     background-color: red;
        //     position: absolute;
        // }
    }
    .num-text {
        width: 90px;
    }
}
.tokenomic {
    .fz-med {
        font-size: 14px;
    }
    .my-tab {
        width: 100%;
        max-width: 1400px;
    }
}
.lock-up {
    max-width: 600px;
    flex: 1;
    overflow: hidden;
}
.tokenomic-title {
    font-size: 26px;
}

#container {
    margin-top:40px;
}

@media screen and (max-width: 768px) {
    .bar {
        width: max-content;
    }
    .chart-wrap {
        flex-direction: column;
        align-items: center;
        // padding-top: 80px;
        // padding: 0 20px;
    }
    .pin-wrap {
        overflow: auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tokenomic {
        padding: 15px !important;
        padding-top: 50px !important;
    }
    .name {
        min-width: 150px !important;
    }
    .tokenomic-title {
        font-size: 18px;
    }
    #container {
        // width: 100% !important;
        min-width: 600px;
        margin-top:0px;
    }
    .lock-up {
        max-width: 100%;
        flex: 1;
        overflow: auto;
    }
    .fz-med {
        font-size: 12px;
    }
 }