.info-area-modal {
    .white-modal-img {
        width: 85px;
        height: 100px;
    }
    .upgrade-btn {
        width: 202px;
        height: 50px;
        line-height: 50px;
        border-radius: 4px;
        padding: 0;
    }
    .step-item {
        position: relative;
        padding-left: 30px;
        margin-left: 30px;
        &.has-border {
            border-left: 1px dashed #979797;
        }
        .step-circle {
            width: 30px;
            height: 30px;
            background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
            border-radius: 25px;
            line-height: 30px;
            position: absolute;
            left: -15px;
        }
    }
    
}