.empty-placeholder {
  margin: 0 auto;
  text-align: center;
  margin-top: 97px;
  .icon-empty {
    width: 124px;
    height: 200px;
    margin: 0 auto; 
    margin-bottom: 30px;
  }

  .text-empty {
    font-size: 16px;
    line-height: 21px;
    color: rgba($color: #fff, $alpha: .6);
  }
}