.search{
    height: 46px;
    width: 260px;
    &.normal {
        border: 1px solid #313134
    }
    input{
        background-color: transparent;
        border: none;
    }
    .icon{
        width: 16px;
        height: 16px;
    }
}
@media screen and (max-width: 768px) {
    .search{
        height: 46px;
        width: auto;
        &.normal {
            border: 1px solid #313134
        }
        input{
            min-width: 0;
            width: 100%;
        }
        .icon{
            width: 16px;
            height: 16px;
        }
    }
}