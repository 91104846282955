.login-content{
    // width: 360px;
    .email{
        position: relative;
        .send-btn{
            position: absolute;
            right: 17px;
            height: 56px;
            top:0;
            line-height: 56px;
        }
        .cap-btn{
            position: absolute;
            right: 0px;
            height: 54px;
            top:0;
            line-height: 56px;
        }
    }
    .fz-16 {
        .ant-input {
            font-size: 16px;
        }
    }
}

.login-avatar{
    img{
        width:20px;
        height:20px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 768px) {
    .send-btn{
        height: 40px !important;
        line-height: 40px !important;
    }
}