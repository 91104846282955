
 .white-bg {
    background: #000 url('../assets/images/mint/mint-bg.png') right top / 100% no-repeat;
    font-family: "BMHANNA" !important

 }
 .top-bar{
     width: 100%;
     height:4px;
 }
.inner-container {
    max-width: 960px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    padding-top:120px;
}
.fz-med {
    font-size: 16px;
}
.fz-mini {
    font-size: 14px;
}
.mint-crumbs {
  color: #fff;
  font-size: 16px;

  .cur {
    color: #E07D26;
  }
}
.white-content-border {
    font-family: "BMHANNA" !important;
    border-top: 1px solid #1E1E1E;
    &:first-child{
        border: none;
    }
}
.submit-btn {
    // width: 80px;
    line-height: 40px !important;
    height: 40px;
    text-align: center;
    padding: 0 5px;
    border: 1px solid #E07D26;
    &:hover {
        color: #E07D26;
        opacity: 0.9;
    }
}
.white {
    a {color: #E07D26;text-decoration: underline;}
    .ant-input[disabled], input {
        // max-width: 80%;
        // width: 395px;
        background-color: #242424;
        outline: none;
        border:0;
        // border-bottom: 1px solid #606060;
        height: 47px !important;
        padding-top: 7px;
        color:#fff;
        &:-internal-autofill-selected {
            border-bottom: 1px solid #606060;
            outline: none;
            background-color: transparent !important;
        }
        &:active {
            
          border-bottom: 1px solid #606060;
          outline: none;
        }
        
        &:focus{
            border-bottom: 1px solid #606060;
            box-shadow: none;
        }
    }
    .submission-btn {
        width: 360px;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        background-color: transparent !important;
    }
}

.note {
    // background-color: rgba(255,255,255,0.06);
    text-align: center;
}
.info-area {
    background: #EFEFEF;
}
.big-title {
    font-size: 30px;
    font-family: "BMHANNA" !important
}
.white-no {
    .num-index {
        line-height: 35px;
    }
}
.white-left {
    flex:1;
}
.done-check {
    margin-left: auto;
}
@media screen and (max-width: 768px) {
    .ant-form-item {
        width: 100%;
        margin-top:10px
    }
    .done-check {
        margin-left:50% !important;
        margin-right: 30px;
        margin-top: 20px;
    }
    .submit-item {
        align-items: flex-start;
    }
    .white-text {
        flex-direction: column;
        align-items: flex-start;
        .m-l-10 {
            margin-left: 0;
        }
    }
    .inner-container {
        padding: 0 15px;
        padding-top: 100px;
    }
    .white .submission-btn {
        width: 280px;
    }
    .fz-mini {
        font-size: 12px;
    }
    .fz-med {
        font-size: 14px;
    }
    .big-title {
        font-size: 24px;
    }
        .white-content {
            flex-direction: column;
            align-items: flex-start;
        }
        .white-bg {
            .ant-form-item-control-input-content {
                // max-width: 280px;
                text-align: center;
            }
        }
        
        .ant-checkbox-wrapper {
            margin-left: auto;
            &.not-robot {
                margin-left: 0;
            }
        }
        .btn-area {
            flex-direction: column;
            align-items: center;
            .submit-btn {
                margin-left: 0;
                margin-top: 4px;
            }
        }
        .bind-btn {
            margin-left: auto;
            margin-top: 4px;
        }
    
    }