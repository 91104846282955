.farm-farm {
  padding-top: 80px;
  color: #fff;
  min-height: 100vh;
  background: url(../../assets/images/farm/farm-bg.png) no-repeat center / cover;

  .is-hidden-mobile {
    display: block !important;
  }
  .is-hidden-desktop {
    display: none !important;
  }
  
  .farm-banner {
    background: url(../../assets/images/farm/farm-banner.png) repeat-x left center / cover;

    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    // padding-left: 8.85416666666667vw;
    // padding-left: 170px;

    .banner-image {
      // position: absolute;
      // left: 0;
      // top: 50%;
      // transform: translateY(-50%);
      // min-width: 1373px;
      // height: 100%;
      // z-index: -1;
    }

    .banner-content {
      padding-left: 170px;
    }

    .banner-title {
      font-size: 20px;
      margin-bottom: 5px;

      a {
        color: #E07D26;
      }
    }

    .banner-subtitle {
      font-size: 16px;
    }
  }

  .farm-content {
    padding: 40px;

    .farm-header {
      display: flex;
      border-bottom: 1px solid #313134;;
      padding-bottom: 20px;
      align-items: center;
      height: 66px;

      .tab-list {
        display: flex;
        margin: 0 -15px;

        .tab-item {
          margin: 0 15px;
          color: rgba($color: #fff, $alpha: .4);
          cursor: pointer;
          position: relative;

          &.active {
            color: #fff;

            &:after {
              content: '';
              position: absolute;
              height: 3px;
              width: 100%;
              background: #E07D26;
              left: 0;
              bottom: -31px;
            }
          }
        }
      }

      .filter-group {
        margin-left: auto;
        display: flex;
      }
    }

    .filter-group-mobile {
      display: none;
    }

    .farm-list {

      .ant-table {
        background-color: transparent;
        color: #fff;

        .ant-table-thead > tr > th {
          background-color: transparent;
        }

        .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
          background-color: transparent;
        }

        .ant-table-tbody > tr > td {
          border-color: #242429;
        }

        .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
          padding: 28px 0;
        }
      }

      .cell-item {

        .cell-title {
          font-size: 14px;
          opacity: .4;
        }

        .cell-value {
          font-size: 16px;
          display: flex;
          align-items: center;

          .token-logo {
            width: 16px;
            height: 16px;
            margin-left: 6px;
            border-radius: 50%;
          }
        }

        .highlight {
          color: #E07D26;
        }
      }

      .cell-item-1 {
        width: 25%;
      }

      .cell-item-op {
        width: 82px;

        &.cell-item-connect-wallet {
          width: 124px;
        }
      }

      .pair-info {
        display: flex;
        align-items: center;
        z-index: 0;

        .token-logo1 {
          width: 34px;
          height: 34px;
          z-index: 1;
          border-radius: 50%;
        }
        .token-logo2 {
          width: 30px;
          height: 30px;
          margin-left: -3px;
          z-index: 0;
          border-radius: 50%;
        }

        .pair-name {
          margin-left: 10px;
          font-size: 16px;
        }

        .link {
          width: 14px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .btn-approve, .btn-stake, .btn-locked, .btn-unstake, .btn-connect {
        width: 82px;
        height: 30px;
        background: #E07D26;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        color: #fff;
        border-radius: 0;
      }

      .btn-stake, .btn-unstake, .btn-connect {
        background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
      }

      .btn-connect {
        width: 125px;
      }

      .btn-locked {
        background: #313134;
      }
    }
  }


  @media (max-width: 768px) {
    padding-top: 60px;

    .is-hidden-mobile {
      display: none !important;
    }
    .is-hidden-desktop {
      display: block !important;
    }

    .farm-banner {
      height: 120px;
      // padding-left: 8.85416666666667vw;
      // padding-left: 170px;
      background: url(../../assets/images/farm/farm-banner.png) no-repeat center / cover;
  
      .banner-image {
        position: absolute;
        // left: 0;
        top: 50%;
        transform: translateY(-50%);
        // min-width: 1373px;
        // height: 100%;
        // z-index: -1;
      }
  
      .banner-content {
        width: 100%;
        padding: 0 15px;
      }
  
      .banner-title {
        font-size: 15px;
        margin-bottom: 5px;
      }
  
      .banner-subtitle {
        font-size: 13px;
      }
    }

    .farm-content {
      padding: 15px;

      .filter-group-mobile {
        display: flex;
        margin: 15px 0;

        & > div {
          width: 50%;

          input {
            width: 100%;
          }

          &:last-child {
            margin-left: 15px;
          }
        }
      }
  
      .farm-header {
        padding-bottom: 15px;
        height: auto;
        .filter-group {
          display: none;
        }

        .tab-list {
          margin: 0 -10px;
  
          .tab-item {
            margin: 0 10px;
  
            &.active {
  
              &:after {
                bottom: -15px;
              }
            }
          }
        }
      }

      .farm-list {
        .farm-item {
          background: #1D1D22;
          border: 1px solid #313134;
          margin-top: 15px;

          .farm-item-header {
            padding: 15px;
            border-bottom: 1px solid #313134;
            display: flex;
            align-items: center;
          }

          .farm-item-body {
            padding: 15px;
          }

          .pair-info {
            margin-right: auto;
            .token-logo1 {
              width: 32px;
              height: 32px;
            }
            .token-logo2 {
              width: 26px;
              height: 26px;
              margin-left: -8px;
            }
    
            .pair-name {
              margin-left: 8px;
              font-size: 16px;
            }
    
            .link {
              width: 14px;
              height: 14px;
              margin-left: 10px;
            }
          }

          .btn-approve, .btn-stake, .btn-locked, .btn-unstake, .btn-connect {
            width: 70px;
            height: 26px;
            font-size: 12px;
          }

          .btn-connect {
            width: 106px;
          }

          .cell-item {
            display: flex;
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }

            .cell-value {
              margin-left: auto;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.stake-modal, .unstake-modal {

  .modal-title {
    display: flex;

    .token-logo {
      width: 30px;
      height: 30px;
      z-index: 1;
      border-radius: 50%;
    }

    .token-logo2 {
      width: 30px;
      height: 30px;
      margin-left: -3px;
      z-index: 0;
      border-radius: 50%;
    }

    .token-name {
      margin-left: 10px;
    }
  }

  .tab-list {
    display: flex;
    font-size: 16px;
    margin-bottom: 30px;
    .tab-item {
      color: #141416;
      padding: 0 10px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        color: #E07D26;
        background-color: rgba($color: #E07D26, $alpha: .1);
      }
    }
  }

  .label {
    color: #727273;
  }

  .value {
  }

  .btn-max {
    color: #fff;
    height: 30px;
    padding: 0 15px;
    background-color: rgba($color: #141416, $alpha: .6);
    border: 0;
    border-radius: 0;
  }

  .apy-block {
    width: 100%;
    height: 54px;
    border: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #141416;
  }

  .btn-stake {
    width: 100%;
    height: 54px;
    background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: #fff !important;
    border-radius: 0;
    font-size: 18px;
  }

  .label-wrapper {
    display: flex;
    font-size: 14px;
  }

  .lock-units-slider {
    .slider-label-wrapper {
      font-size: 14px;
      display: flex;
    }
    .lock-units {
      color: #E07D26;
    }

    .range {
      font-size: 14px;
      color: #727273;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
  
      .min {

      }
      .max {

      }
    }

    .ant-slider {
      margin: 20px 0 5px;
    }

    .ant-slider-rail {
      background: #E9E9E9;
    }

    .ant-slider-track {
      background: linear-gradient(147deg, #3B3AD0 0%, #FC7500 100%);
    }

    .ant-slider-handle {
      background: #E07D26;;
      border: solid 2px #fff !important;
    }
    .ant-slider-handle:focus {
      box-shadow: 0 0 0 5px rgb(24 144 255 / 12%);
    }

    .ant-slider-handle:focus, .unstake-modal .lock-units-slider .ant-slider-handle:focus {
      box-shadow: none;
    }
  }

  .ant-input {
    background: transparent;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  @media (max-width: 768px) {
    .modal-title {
  
      .token-logo {
        width: 24px;
        height: 24px;
      }
  
      .token-logo2 {
        width: 24px;
        height: 24px;
        margin-left: -3px;
        z-index: 0;
        border-radius: 50%;
      }
  
      .token-name {
        margin-left: 8px;
      }
    }

    .modal-content {
      padding: 20px;
    }

    .my-input {
      margin-top: 8px;
    }

    .label-wrapper {
      font-size: 11px;
    }

    .tab-list {
      font-size: 12px;
      margin-bottom: 20px;
      .tab-item {
        padding: 0 10px;
        height: 24px;
      }
    }
  
    .btn-max {
      font-size: 11px;
      color: #fff;
      height: 23px;
      padding: 0 12px;
    }
  
    .apy-block {
      height: 40px;
      font-size: 11px;
      margin-top: 15px;
    }
  
    .btn-stake {
      height: 41px;
      font-size: 14px;
      margin-top: 24px;
    }
  
    .lock-units-slider {
      .slider-label-wrapper {
        font-size: 11px;
      }
      .lock-units {
        color: #E07D26;
      }
  
      .range {
        font-size: 11px;
        margin-bottom: 23px;
    
        .min {
  
        }
        .max {
  
        }
      }
  
      .ant-slider {
        margin: 16px 0 5px;
      }
    }

    .warning {
      margin-top: 14px;
      margin-bottom: 24px;
      font-size: 11px;
      img {
        width: 13px;
        height: 13px;
        margin-right: 7px;
      }
    }
  }
}
