.claim {
    min-height: 100vh;
    background: #141416;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    *{

        z-index: 9;
    }
    .verify-btn {
        width: 240px;
        height: 64px;
        line-height: 64px;
        margin: 0 auto
    }
    .bg {
        left:0;
        right: 0;
        position: absolute;
        z-index: 1;
        bottom: 0;
    }
    .next {
        color: #fff;
        width: 200px;
        height: 46px;
        border: 1px solid #313134;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
    }
}
@media screen and (max-width: 768px) {
    .claim {
        padding-left:20px;
        padding-right:20px;
    }
}