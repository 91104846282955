.airdrop-claim {
    background: #F7F7FA;
    min-height: 100vh;
    font-family: DINPro;
    .air-header {
        background-color: #000;
        height: 100px;
    }
    .claim-area {
        width: 960px;
        background-color: #fff;
        padding: 30px 74px;
        border-radius: 80px;
        margin-top: -62px;
        .claim-item{
            padding: 14px 37px 14px 14px;
            &.active {   
                background: #F7F7FA;
                border-radius: 35px;
            }
        }
        .token-logo {
            width: 42px;
            height: 42px;
        }
        .claim-btn {
            text-align: center;
            // width: 100px;
            height: 36px;
            padding: 0 10px;
            min-width: 100px;
            // line-height: 34px;
            font-size: 14px;
            font-family: DINPro-Medium, DINPro;
            font-weight: 500;
            color: #374AFF;
            background: linear-gradient(250deg, rgba(34, 83, 255, 0.12) 0%, rgba(166, 229, 255, 0.2) 100%);
            border-radius: 18px;
            border: 1px solid rgba(166, 229, 255, 1);
        }
        .state-title {
            width: 100px;
            text-align: center;
        }
        .claim-title {
            color: rgba(0,0,0,0.6);
            padding-right: 37px;
        }
    }
    
        
    .footer{
        position: relative;
        margin: 0 auto;
        margin-bottom: 30px;
        .mini-logo {
            width: 87px;
        }
    }
}