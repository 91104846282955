.global-header {
  display: flex;
  // margin-bottom: 100px;
  padding: 0 40px;
  align-items: center;
  position: fixed;
  z-index: 1000;
  height: 80px;
  width: 100%;
  top: 0;
  transition: background-color .4s;
  color: #fff;

  &.has-bg {
    background-color: #141416;
    border-bottom: 1px solid #242429;
  }

  .white-img {
    width: 30px;
    height: 37px;
  }
  .logo {
    width: 163px;
    height: 30px;
    margin-right: 80px;
    flex-shrink: 0;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .nav-list {
    display: flex;
    margin: 0 -15px 0;
    font-weight: 500;
    font-size: 16px;
    height: calc(100% - 6px);

    .nav-item {
      margin: 0 15px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      .icon-arrow {
        width: 6px;
        height: 4px;
        margin-left: 4px;
        position: relative;
        top: 1px;
        transition: transform 0.4s ease 0s;
      }

      a {
        height: 100%;
        cursor: pointer;
        transition: transform 0.4s ease 0s;
        display: flex;
        align-items: center;

        &:hover {
          transform: translateY(-2px);

          .icon-arrow {
            transform: rotate(180deg);
          }
        }
      }

      &.active, &:hover {
        // &::after {
        //   content: '';
        //   width: 100%;
        //   // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
        //   position: absolute;
        //   border-bottom: 1px solid #fff;
        //   left: 0;
        //   bottom: 0;
        // }
      }

      // &.active {
      //   a {
      //     &:hover {
      //       transform: none;
      //     }
      //   }
      // }
    }
  }

  .header-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: calc(100% - 6px);

    .icon-list {
      display: flex;
      margin: 0 -15px;
      height: 100%;

      &>div {
        flex-shrink: 0;
      }

      .icon-item {
        margin: 0 15px;
        flex-shrink: 0;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  .mobile-menu-panel {
    position: fixed;
    top: 0px;
    left: -228px;
    transition: all 0.2s ease-in-out;

    &.mobile-menu-panel-show {
      left: 0;
    }

    .menu {
      padding-top: 0;
      height: calc(100vh - 50px);
    }

    .menu .menu-items {
      // padding-top: 0;
    }
  }

  @media screen and (max-width: 767.5px) {
    height: 60px;
    padding: 0 20px;
    background-color: #141416;
    justify-content: center;
    z-index: 1000;

    .logo {
      width: 130px;
      height: 24px;
      margin-right: 0;
    }

    .nav-list {
      display: none;
    }

    .header-right {
      display: none;
    }

    .mobile-menu {
      width: 18px;
      height: 14px;
      position: absolute;
      right: 20px;
      top: 20px;
      display: block;
      z-index: 100;
    }
    .login-mobile{
      position: absolute;
      left: 20px;
      top: 18px;
      display: block;
    }
  }
}

.ant-dropdown-menu {
  background: #1c1c1e;
 
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu{
    padding: 20px 20px 20px 25px;
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background: #2a2a2a;
  }
  a {
    display: flex;
    align-items: center;
  }
  .ant-dropdown-menu-item {
    .arrow {
      width: 15px;
      margin-left: auto;
      img{
        display: none;
      }
    }
    &:hover {
      .arrow {
        img{
          display: inline-block;
        }
      }
    }
  }
  .menu-wrapper {
    margin-left: 20px;
  }
  .menu-title, .ant-dropdown-menu-title-content {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    &.fz-16{
      font-size: 16px ;
    }
    &.fz-14{
      font-size: 14px ;
    }
    
    .comingsoon {
      color: rgba($color: #fff, $alpha: .4);
      font-size: 14px;
      line-height: 18px;
      margin-left: 3px;
      margin-top: 3px
    }
  }

  .menu-title-sub {
    color: rgba($color: #fff, $alpha: .4);
    font-size: 14px;
    line-height: 18px;
  }
}

.header-nav-dropdown {
}