.grid {
  pointer-events: none !important;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
}
.grid-outer {
  height: 100%;
}
.grid-inner {
  display: flex;
  flex-wrap: wrap;
}
.grid-item {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}
.grid-item:before {
  content: "";
  display: block;
  padding-bottom: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.grid-cross {
  position: absolute;
  z-index: 1;
}
.grid-cross:after,
.grid-cross:before {
  content: "";
  position: absolute;
  display: block;
}
.grid-cross:before {
  left: 0;
  height: 1px;
}
.grid-cross:after {
  top: 0;
  width: 1px;
}
.grid-edge {
  position: absolute;
  z-index: 1;
}
.grid-edge:after,
.grid-edge:before {
  content: "";
  position: absolute;
  display: block;
}
.grid-edge.is-tl {
  top: -1px;
  left: -1px;
}
.grid-edge.is-tl:before {
  top: 0;
  left: 0;
  height: 1px;
}
.grid-edge.is-tl:after {
  top: 0;
  left: 0;
  width: 1px;
}
.grid-edge.is-tr {
  top: -1px;
  right: -1px;
}
.grid-edge.is-tr:before {
  top: 0;
  right: 0;
  height: 1px;
}
.grid-edge.is-tr:after {
  top: 0;
  right: 0;
  width: 1px;
}
.grid-edge.is-bl {
  bottom: -1px;
  left: -1px;
}
.grid-edge.is-bl:before {
  bottom: 0;
  left: 0;
  height: 1px;
}
.grid-edge.is-bl:after {
  top: 0;
  left: 0;
  width: 1px;
}
.grid-edge.is-br {
  bottom: -1px;
  right: -1px;
}
.grid-edge.is-br:before {
  bottom: 0;
  left: 0;
  height: 1px;
}
.grid-edge.is-br:after {
  top: 0;
  right: 0;
  width: 1px;
}
.grid-box__group {
  position: absolute;
}
.grid-box__group.is-horizontal {
  top: 42.5%;
  left: 42.5%;
  width: 25%;
  height: 25%;
}
.grid-box__group.is-horizontal .grid-box__group-cross {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: calc(100% + 0.001px);
}
.grid-box__group.is-horizontal .grid-box__group-cross:first-child {
  left: 0;
}
.grid-box__group.is-horizontal .grid-box__group-cross:nth-child(2) {
  left: calc(100% + 1px);
}
.grid-box__group.is-horizontal .grid-box__group-cross:nth-child(3) {
  left: calc(200% + 2px);
}
.grid-box__group.is-horizontal--cross {
  top: 12.5%;
  left: 32.5%;
  width: 12.5%;
  height: 12.5%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:first-child {
  left: 0;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(2) {
  left: 100%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(3) {
  left: 200%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(4) {
  left: 300%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(5) {
  left: 400%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(6) {
  left: 500%;
}
.grid-box__group.is-horizontal--cross .grid-box__group-none:nth-child(7) {
  left: 600%;
}
.grid-box__group.is-vertical--cross {
  top: -12.5%;
  right: 12.5%;
  width: 12.5%;
  height: 12.5%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:first-child {
  top: 0;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(2) {
  top: 100%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(3) {
  top: 200%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(4) {
  top: 300%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(5) {
  top: 400%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(6) {
  top: 500%;
}
.grid-box__group.is-vertical--cross .grid-box__group-none:nth-child(7) {
  top: 600%;
}
.grid-box__group.is-vertical {
  top: 42.5%;
  left: 12.5%;
  width: 25%;
  height: 25%;
}
.grid-box__group.is-vertical .grid-box__group-cross {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: calc(100% + 0.001px);
}
.grid-box__group.is-vertical .grid-box__group-cross:first-child {
  top: 0;
}
.grid-box__group.is-vertical .grid-box__group-cross:nth-child(2) {
  top: calc(100% + 1px);
}
.grid-box__group.is-vertical .grid-box__group-cross:nth-child(3) {
  top: calc(200% + 2px);
}
.grid-box__group.is-landscape {
  top: 87.5%;
  left: 25%;
  width: 50%;
  height: 25%;
}
.grid-box__group.is-landscape .grid-box__group-box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: calc(100% + 0.001px);
}
.grid-box__group.is-landscape .grid-box__group-box:first-child {
  border-right: none;
  left: 0;
}
.grid-box__group.is-landscape .grid-box__group-box:nth-child(2) {
  left: calc(100% + 1px);
}
.grid-box {
  position: absolute;
  width: 50%;
  height: 50%;
  background-size: calc(100% + 0.001px);
}
.grid-box.is-tl {
  top: 0;
  left: 1px;
}
.grid-box.is-tr {
  width: 50%;
  height: 50%;
  top: 0;
  left: calc(50% + 0.001px);
}
.grid-box.is-br {
  bottom: 1px;
  left: calc(50% + 0.001px);
}
.grid-box.is-bl {
  top: calc(50% + 0.001px);
  left: 1px;
}
@media screen and (max-width: 767.5px) {
  .show--l {
    display: none !important;
  }
  .grid {
    position: absolute;
  }
  .grid-item {
    flex-basis: 25%;
  }
  .grid-item:before {
    border-bottom-color: hsla(0, 0%, 100%, 0.07);
  }
  .grid-item:not(:nth-child(4n + 1)):before {
    border-left: 1px solid hsla(0, 0%, 100%, 0.07);
  }
  .grid-cross {
    width: 1.86667vw;
    height: 1.86667vw;
  }
  .grid-cross:after,
  .grid-cross:before {
    background-color: rgba(70, 70, 70, 0.7);
  }
  .grid-cross:before {
    top: 0.8vw;
    width: 1.86667vw;
  }
  .grid-cross:after {
    left: 0.8vw;
    height: 1.86667vw;
  }
  .grid-cross.is-tl {
    left: -0.8vw;
    top: -0.8vw;
  }
  .grid-cross.is-tr {
    right: -0.8vw;
    top: -0.8vw;
  }
  .grid-cross.is-br {
    bottom: -0.8vw;
    right: -0.8vw;
  }
  .grid-cross.is-bl {
    bottom: -0.8vw;
    left: -0.8vw;
  }
  .grid-edge {
    width: 0.66667vw;
    height: 0.66667vw;
  }
  .grid-edge:after,
  .grid-edge:before {
    background-color: rgba(70, 70, 70, 0.7);
  }
  .grid-edge.is-tl:before {
    width: 0.66667vw;
  }
  .grid-edge.is-tl:after {
    height: 0.66667vw;
  }
  .grid-edge.is-tr:before {
    width: 0.66667vw;
  }
  .grid-edge.is-tr:after {
    height: 0.66667vw;
  }
  .grid-edge.is-bl:before {
    width: 0.66667vw;
  }
  .grid-edge.is-bl:after {
    height: 0.66667vw;
  }
  .grid-edge.is-br:before {
    width: 0.66667vw;
  }
  .grid-edge.is-br:after {
    height: 0.66667vw;
  }
  .grid-box__group.is-horizontal .grid-box__group-cross {
    background-image: linear-gradient(
        45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      ),
      linear-gradient(
        -45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      );
  }
  .grid-box__group.is-horizontal--cross .grid-box__group-none .grid-cross {
    width: 1.06667vw;
    height: 1.06667vw;
  }
  .grid-box__group.is-horizontal--cross .grid-box__group-none .grid-cross:before {
    top: 0.4vw;
    width: 1.06667vw;
  }
  .grid-box__group.is-horizontal--cross .grid-box__group-none .grid-cross:after {
    left: 0.4vw;
    height: 1.06667vw;
  }
  .grid-box__group.is-vertical--cross .grid-box__group-none .grid-cross {
    width: 1.06667vw;
    height: 1.06667vw;
  }
  .grid-box__group.is-vertical--cross .grid-box__group-none .grid-cross:before {
    top: 0.4vw;
    width: 1.06667vw;
  }
  .grid-box__group.is-vertical--cross .grid-box__group-none .grid-cross:after {
    left: 0.4vw;
    height: 1.06667vw;
  }
  .grid-box__group.is-vertical .grid-box__group-cross {
    background-image: linear-gradient(
        45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      ),
      linear-gradient(
        -45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      );
  }
  .grid-box__group.is-landscape .grid-box__group-box {
    border: 1px solid hsla(0, 0%, 100%, 0.07);
    background-image: linear-gradient(
      26.25deg,
      transparent calc(50% - 0.5px),
      hsla(0, 0%, 100%, 0.07) calc(50% - 0.5px),
      hsla(0, 0%, 100%, 0.07) calc(50% + 0.5px),
      transparent calc(50% + 0.5px)
    );
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross {
    width: 1.06667vw;
    height: 1.06667vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-tl {
    top: -0.53333vw;
    left: -0.53333vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-tr {
    top: -0.53333vw;
    right: -0.53333vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-br {
    bottom: -0.53333vw;
    right: -0.53333vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-bl {
    bottom: -0.53333vw;
    left: -0.53333vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross:before {
    top: 0.4vw;
    width: 1.06667vw;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross:after {
    left: 0.4vw;
    height: 1.06667vw;
  }
  .grid-box {
    background-image: linear-gradient(
        45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      ),
      linear-gradient(
        -45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.07) 50%,
        transparent 0,
        transparent
      );
  }
  .grid-box.is-tl {
    border-right: 1px solid hsla(0, 0%, 100%, 0.07);
  }
  .grid-box.is-tl,
  .grid-box.is-tr {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.07);
  }
  .grid-box.is-br,
  .grid-box.is-tr {
    border-left: 1px solid hsla(0, 0%, 100%, 0.07);
  }
  .grid-box.is-bl,
  .grid-box.is-br {
    border-top: 1px solid hsla(0, 0%, 100%, 0.07);
  }
  .grid-box.is-bl {
    border-right: 1px solid hsla(0, 0%, 100%, 0.07);
  }
}
@media screen and (min-width: 768px) {
  .show--s {
    display: none !important;
  }
  .grid {
    position: fixed;
    top: 0;
    left: 0;
    height: 999999px !important;
  }
  .grid-item {
    flex-basis: 20%;
  }
  .grid-item:before {
    border-bottom-color: hsla(0, 0%, 100%, 0.1);
  }
  .grid-item:not(:nth-child(5n + 1)):before {
    border-left: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  .grid-cross {
    width: 5px;
    height: 5px;
  }
  .grid-cross:after,
  .grid-cross:before {
    background-color: #797979;
  }
  .grid-cross:before {
    top: 2px;
    width: 5px;
  }
  .grid-cross:after {
    left: 2px;
    height: 5px;
  }
  .grid-cross.is-tl {
    left: -2px;
    top: -2px;
  }
  .grid-cross.is-tr {
    right: -2px;
    top: -2px;
  }
  .grid-cross.is-br {
    bottom: -2px;
    right: -2px;
  }
  .grid-cross.is-bl {
    bottom: -2px;
    left: -2px;
  }
  .grid-edge {
    width: 4px;
    height: 4px;
  }
  .grid-edge:after,
  .grid-edge:before {
    background-color: #606060;
  }
  .grid-edge.is-tl:before {
    width: 4px;
  }
  .grid-edge.is-tl:after {
    height: 4px;
  }
  .grid-edge.is-tr:before {
    width: 4px;
  }
  .grid-edge.is-tr:after {
    height: 4px;
  }
  .grid-edge.is-bl:before {
    width: 4px;
  }
  .grid-edge.is-bl:after {
    height: 4px;
  }
  .grid-edge.is-br:before {
    width: 4px;
  }
  .grid-edge.is-br:after {
    height: 4px;
  }
  .grid-box__group.is-horizontal .grid-box__group-cross,
  .grid-box__group.is-vertical .grid-box__group-cross {
    background-image: linear-gradient(
        45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) 50%,
        transparent 0,
        transparent
      ),
      linear-gradient(
        -45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) 50%,
        transparent 0,
        transparent
      );
  }
  .grid-box__group.is-landscape .grid-box__group-box {
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    background-image: linear-gradient(
      26.25deg,
      transparent calc(50% - 0.5px),
      hsla(0, 0%, 100%, 0.1) calc(50% - 0.5px),
      hsla(0, 0%, 100%, 0.1) calc(50% + 0.5px),
      transparent calc(50% + 0.5px)
    );
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-tl {
    top: -3px;
    left: -3px;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-tr {
    top: -3px;
    right: -3px;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-br {
    bottom: -3px;
    right: -3px;
  }
  .grid-box__group.is-landscape .grid-box__group-box .grid-cross.is-bl {
    bottom: -3px;
    left: -3px;
  }
  .grid-box {
    background-image: linear-gradient(
        45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) 50%,
        transparent 0,
        transparent
      ),
      linear-gradient(
        -45deg,
        transparent calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) calc(50% - 1px),
        hsla(0, 0%, 100%, 0.1) 50%,
        transparent 0,
        transparent
      );
  }
  .grid-box.is-tl {
    border-right: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  .grid-box.is-tl,
  .grid-box.is-tr {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  .grid-box.is-br,
  .grid-box.is-tr {
    border-left: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  .grid-box.is-bl,
  .grid-box.is-br {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  .grid-box.is-bl {
    border-right: 1px solid hsla(0, 0%, 100%, 0.1);
  }
}
