.airdrop {
    background-color: #050215;
    min-height: 100vh;
    font-family: DINPro;
    .airdrop-banner {
        position: relative;
        background: url('../assets/images/airdrop/airbg.png') center top no-repeat;
        background-size: 1920px 975px;
        height: 975px;
        .dotbg {
            position: absolute;
        }
        .airdrop-banner-content {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            .air-left-part {
                width: 530px;
                .kepler {
                    font-family: DINPro-Bold;
                    font-size: 56px;
                    font-weight: bold;
                    color: #FFFFFF;
                    // line-height: 44px;
                    background: linear-gradient(70deg, #2253FF 0%, #A6E5FF 100%, #A6E5FF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .slogan{
                    font-size: 56px;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 60px;
                    font-family: DINPro-Bold;
                }
                .air-btn {
                    width: 240px;
                    height: 72px;
                    border-radius: 72px;
                }
                .white-btn{
                    background: linear-gradient(270deg, rgba(255, 136, 31, 0.2) 0%, rgba(55, 74, 255, 0.15) 100%);
                    border: 1px solid #FC7500; 
                    // border-image: radial-gradient(circle, rgba(59, 58, 208, 1), rgba(252, 117, 0, 1)) 1 1;
                }
            }
            .air-right-part {
                width: 530px;
                .logo-area{
                    position: relative;
                    &:hover{
                        .hover{
                            display: block;
                        }
                    }
                    .hover {
                        position: absolute;
                        left:-33px;
                        top: -12px;
                        z-index: 0;
                        width:147px;
                        max-width: 147px;
                        display: none;
                    }
                }
                .token-logo {
                    width: 80px;
                    height: 80px;
                }
                .progress-outter {
                    height: 20px;
                    background: rgba(216, 216, 216, 0.15);
                    border-radius: 10px;
                    padding: 0 5px;
                    position: relative;
                    .progress-inner {
                        min-width: 12px;
                        height: 12px;
                        background: linear-gradient(242deg, #003ABB 0%, #846EFF 100%);
                        border-radius: 6px;
                        &.looksrare {
                                background: linear-gradient(270deg, #00D244 0%, #374AFF 100%);
                        }
                        
                        &.bend {
                            background: linear-gradient(270deg, #E02020 0%, #FA6400 17%, #F7B500 33%, #6DD400 50%, #0091FF 67%, #6236FF 83%, #B620E0 100%);
                        }
                    }
                    .progress-text {
                        position: absolute;
                        right: 5px;
                    }
                }
            }
            .arrow {
                width: 169px;
                height:117px;
                position: absolute;
                left: 530px;
                bottom: -210px;
            }
            
            .who {
                width: 570px;
                height: 147px;
                left: -120px;
                bottom: -210px;
                position: absolute;
                background: url('../assets/images/airdrop/who.png')   no-repeat;
                background-size: cover;
                .flower{
                    position: absolute;
                    left:72px;
                    top: 65px;
                }
                .message {
                    position: absolute;
                    left: 148px;
                    top: 60px;
                    .msg-item {
                        width: 350px;
                    }
                }
                .avatar {
                    width: 24px;
                    height:24px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }
    .airdrop-info {
        position: relative;
        background: url('../assets/images/airdrop/airinfobg.png') center top no-repeat;
        background-size: 1920px 731px;
        height: 731px;
        .lines-img {
            position: absolute;
            bottom: 0
        }
        .airdrop-info-content {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            .metrics {
                width: 316px;
                position: absolute;
                left: 162px;
                top:90px;
                .metrics-title {
                    line-height: 50px;
                }
            }
            .giveaway {
                .giveaway-title {
                    font-family: DINPro-Bold;
                    line-height: 30px;
                }
                width: 139px;
                position: absolute;
            }
            .giveaway-area {
                top:89px;
                left: 683px;
            }
            .start {
                top:252px;
                left: 683px;
            }
            .snapshot {
                top:149px;
                left: 938px;
            }
            .end {
                top:311px;
                left: 938px;
            }
            .question {
                width: 363px;
                top: 494px;
                position: relative;
                text-align: center;
                margin: 0 auto;
                .line {
                    position: absolute;
                    background: #1B2631;
                    &.line1{
                        width: 307px;
                        height: 1px;
                        bottom: -16px;
                        left: -23px;
                    }
                    &.line2{
                        width: 1px;
                        height: 91px;
                        top: -12px;
                        left: -17px;
                    }
                    &.line3{
                        width: 53px;
                        height: 1px;
                        top: -16px;
                        right: -25px;
                    }
                    &.line4{
                        width: 1px;
                        height: 76px;
                        top: -23px;               
                        right: -16px;
                    }
                }
            }
            .star {
                position: absolute;
                &.star1 {
                    top: 38px;
                    right: 0;
                }
                &.star2 {
                    left: 0px;
                    top: 408px;
                }
                &.star3 {
                    left: 438px;
                    top: 69px;
                }
            }
            .footer{
                top: 634px;
                position: relative;
                margin: 0 auto;
                .mini-logo {
                    width: 87px;
                }
            }
        }
    }
}