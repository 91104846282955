.mint-footer {
  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  // background-color:#141416;
  z-index: 20;

  .footer-content {
    display: flex;
    align-items: center;
  }

  .footer-content-left {
    .logo-w {
      height: 40px;
    }
    
    .logo-w {
      display: inline-block;
    }
    .logo-l{
      display: none
    }
  }

  .footer-content-right {
    margin-left: auto;
  }

  .footer-link-group {
    &:last-child {
      margin-right: 0;
    }

    .footer-link-item {
      margin-bottom: 16px;
      display: block;
      cursor: pointer;
      &.bl {
        border-left: 1px solid rgba(216, 216, 216, .1)
      }

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }


  .footer-bottom {
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    font-size: 16px;
  }

  .social-list {
    display: flex;
    margin: 0 -5px;

    .social-item {
      margin: 0 5px;
      cursor: pointer;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #1D1D22;
      }
    }
  }

  @media screen and (max-width: 767.5px) {
    .mint-footer {
      padding-bottom: 0 !important;
    }
    .social-list{
      margin-bottom: 40px;
    }
    .footer-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .footer-content-left {
        margin: 0 auto;
        .logo-w {
          display: none;
        }
        .logo-l{
          display: inline-block;
          margin-bottom: 40px;
        }
        // .footer-logo {
        //   width: 80px;
        //   height: 80px;
        // }
      }
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }

    .copyright {
      margin-bottom: 32px;
    }
    
    .footer-content-right {
      margin-left: 0;
      align-items: center;
    }

  }
}