.box{
    background-color: #fff;
    height: 32px;
    width: 32px;
    line-height: 32px;
    color: #141416
}
.tip {
    width: 32px;
    height: 16px;
    line-height: 18px;
    background: rgba(255, 255,255,0.1);
    color: rgba(255, 255,255,0.3);
}