.global-footer {
  padding-top: 185px;
  padding-bottom: 64px;
  position: relative;
  z-index: 20;

  .footer-content {
    display: flex;
    margin-bottom: 80px;
    align-items: center;
  }

  .footer-content-left {
    .footer-logo {
      width: 140px;
      height: 141px;
    }
  }

  .footer-content-right {
    margin-left: auto;
  }

  .footer-link {
    display: flex;
    font-size: 18px;
    margin-left: auto;

    .footer-link-group {
      margin-right: 114px;

      &:last-child {
        margin-right: 0;
      }

      .footer-link-item {
        margin-bottom: 16px;
        display: block;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }


  .footer-bottom {
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    font-size: 16px;
  }

  .social-list {
    display: flex;
    margin: 0 -5px;

    .social-item {
      margin: 0 5px;
      cursor: pointer;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #1D1D22;
      }
    }
  }

  @media screen and (max-width: 767.5px) {
    padding-top: 68px;
    padding-bottom: 56px;

    .footer-content {
      margin-bottom: 30px;

      .footer-content-left {
        margin: 0 auto;
        .footer-logo {
          width: 80px;
          height: 80px;
        }
      }
  
      .footer-content-right {
        display: none;
      }
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }

    .copyright {
      margin-bottom: 32px;
    }
  }
}